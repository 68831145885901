import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { CreateEventFormStore } from "@/organization/occurrence/create-form/CreateEventForm"
import { EditEventDrawerFormStore } from "@/organization/occurrence/event-drawer/EditEventDrawerContext"
import { GoogleMapAddressComponents } from "@/organization/occurrence/__generated__/EventDrawerTitleMutation.graphql"
import { OccurrencePhysicalAddressInputPlaceIdToAddressQuery } from "@/organization/occurrence/__generated__/OccurrencePhysicalAddressInputPlaceIdToAddressQuery.graphql"
import { OccurrencePhysicalAddressInputSuggestionsQuery } from "@/organization/occurrence/__generated__/OccurrencePhysicalAddressInputSuggestionsQuery.graphql"
import { OccurrencePhysicalAddressInput_ProductFragment$key } from "@/organization/occurrence/__generated__/OccurrencePhysicalAddressInput_ProductFragment.graphql"
import Relay from "@/relay/relayUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoTextField } from "@disco-ui"
import { Autocomplete, AutocompleteInputChangeReason } from "@material-ui/lab"
import useDebounce from "@utils/hook/useDebounce"
import { TestIDProps } from "@utils/typeUtils"
import { observer } from "mobx-react-lite"
import { useState } from "react"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"

interface OccurrencePhysicalAddressInputProps extends TestIDProps {
  productKey: OccurrencePhysicalAddressInput_ProductFragment$key | null
  form: CreateEventFormStore | EditEventDrawerFormStore
}

function OccurrencePhysicalAddressInput({
  testid = "OccurrencePhysicalAddressInput",
  productKey,
  form,
}: OccurrencePhysicalAddressInputProps) {
  const classes = useStyles()
  const activeOrganization = useActiveOrganization()!
  const [options, setOptions] = useState<Array<GoogleMapAddressComponents>>([])
  const [addressInputValue, setAddressInputValue] = useState(
    form.state.physicalAddress?.displayAddress || ""
  )
  const debounceSearchTextChange = useDebounce(
    (newSearchText: string, reason: AutocompleteInputChangeReason) => {
      if (reason === "input" || reason === "clear") onInputChange(newSearchText)
    },
    500
  )

  const product = useFragment<OccurrencePhysicalAddressInput_ProductFragment$key>(
    graphql`
      fragment OccurrencePhysicalAddressInput_ProductFragment on Product {
        id
      }
    `,
    productKey
  )

  return (
    <Autocomplete
      freeSolo
      fullWidth
      options={options}
      onChange={async (_, value) => {
        if (!value) return
        const address = await getAddressFromPlaceId(
          (value as GoogleMapAddressComponents).placeId
        )
        if (!address) return
        form.state.physicalAddress = address
      }}
      onInputChange={(
        _,
        newSearchText: string,
        reason: AutocompleteInputChangeReason
      ) => {
        setAddressInputValue(newSearchText)
        debounceSearchTextChange(newSearchText, reason)
      }}
      getOptionLabel={(option: GoogleMapAddressComponents) =>
        option.displayAddress || option.address || ""
      }
      value={form.state.physicalAddress || null}
      inputValue={addressInputValue}
      renderInput={(params) => (
        <DiscoTextField
          variant={"standard"}
          placeholder={"Start typing to find an address"}
          className={classes.addressTextField}
          {...params}
        />
      )}
      filterOptions={(x) => x} // want to show everything that's returned from the API, with no filters applied
      data-testid={`${testid}.address-input`}
      ListboxProps={{ "data-testid": `${testid}.address-input-listbox` }}
    />
  )

  async function getAddressSuggestions(query: string) {
    const response = await Relay.runQuery<OccurrencePhysicalAddressInputSuggestionsQuery>(
      graphql`
        query OccurrencePhysicalAddressInputSuggestionsQuery(
          $input: GoogleMapsSuggestionsInput!
        ) {
          googleMapsSuggestions(input: $input) {
            displayAddress
            placeId
          }
        }
      `,
      {
        input: {
          organizationId: activeOrganization.id,
          productId: product?.id,
          query,
        },
      },
      { fetchPolicy: "network-only" }
    )

    if (!response?.googleMapsSuggestions) {
      setOptions([])
      return
    }

    setOptions(response.googleMapsSuggestions as Array<GoogleMapAddressComponents>)
  }

  async function getAddressFromPlaceId(
    placeId: string
  ): Promise<GoogleMapAddressComponents | undefined> {
    const response =
      await Relay.runQuery<OccurrencePhysicalAddressInputPlaceIdToAddressQuery>(
        graphql`
          query OccurrencePhysicalAddressInputPlaceIdToAddressQuery(
            $input: GoogleMapsPlaceToAddressInput!
          ) {
            placeToAddress(input: $input) {
              displayAddress
              address
              name
              placeId
              lat
              lng
            }
          }
        `,
        {
          input: {
            organizationId: activeOrganization.id,
            productId: product?.id,
            placeId,
          },
        },
        { fetchPolicy: "network-only" }
      )

    if (!response?.placeToAddress) {
      form.addError({
        field: "physicalAddress",
        message: "Unable to set address, please try again.",
      })
      return
    }
    return response.placeToAddress as GoogleMapAddressComponents
  }

  function onInputChange(value: string) {
    setOptions([])
    if (value) {
      getAddressSuggestions(value)
    } else {
      form.state.physicalAddress = undefined
    }
  }
}

const useStyles = makeUseStyles((theme) => ({
  addressTextField: {
    "& input": {
      ...theme.typography["body-sm"],
      padding: `${theme.spacing(1, 1)} !important`,
    },
  },
}))

export default observer(OccurrencePhysicalAddressInput)
