import EventFeedbackForm from "@/occurrence/feedback/form/EventFeedbackForm"
import { GlobalID } from "@/relay/RelayTypes"
import DiscoModal from "@disco-ui/modal/DiscoModal"
import { useTheme } from "@material-ui/core"
import { observer } from "mobx-react-lite"

interface EventFeedbackModalProps {
  isOpen: boolean
  onClose: () => void
  occurrenceId: GlobalID
  onSubmitFeedback: () => void
  previewMode?: boolean
}

const EventFeedbackModal = observer<EventFeedbackModalProps>(
  ({ isOpen, onClose, occurrenceId, onSubmitFeedback, previewMode }) => {
    const theme = useTheme()
    return (
      <DiscoModal
        isOpen={isOpen}
        modalContentLabel={"EventFeedbackModal"}
        onClose={onClose}
        title={"How was the event?"}
        subtitle={
          "Feedback helps us improve the next event and make it a better experience for you."
        }
        testid={"EventFeedbackModal"}
        width={`${theme.measure.modalMaxWidth.large}px`}
        buttons
        body={
          <EventFeedbackForm
            testid={"EventFeedbackForm"}
            occurrenceId={occurrenceId}
            onSubmitFeedback={onSubmitFeedback}
            onClose={onClose}
            previewMode={previewMode}
          />
        }
      />
    )
  }
)

export default EventFeedbackModal
