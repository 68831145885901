/**
 * @generated SignedSource<<c32996399fcbf297cef5708de3046264>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type OccurrenceStatus = "draft" | "published" | "%future added value";
export type ProductStatus = "draft" | "published" | "archived" | "unlisted" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type occurrenceUtils_useCanShareOccurrenceFragment$data = {
  readonly status: OccurrenceStatus;
  readonly atCapacity: boolean;
  readonly product: {
    readonly status: ProductStatus;
    readonly " $fragmentSpreads": FragmentRefs<"useIsWaitingRoomEnabledActiveProductFragment">;
  };
  readonly " $fragmentType": "occurrenceUtils_useCanShareOccurrenceFragment";
};
export type occurrenceUtils_useCanShareOccurrenceFragment$key = {
  readonly " $data"?: occurrenceUtils_useCanShareOccurrenceFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"occurrenceUtils_useCanShareOccurrenceFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "occurrenceUtils_useCanShareOccurrenceFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "atCapacity",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Product",
      "kind": "LinkedField",
      "name": "product",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "useIsWaitingRoomEnabledActiveProductFragment"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Occurrence",
  "abstractKey": null
};
})();

(node as any).hash = "92d0d335a3bfaacaf089e41d8719e4ef";

export default node;
