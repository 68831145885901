/**
 * @generated SignedSource<<a30cfe0f4ce363ce7bebe8f9cbe9cce0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type OccurrenceWithDetailsFragment$data = {
  readonly id: string;
  readonly name: string | null;
  readonly datetimeRange: ReadonlyArray<string>;
  readonly " $fragmentType": "OccurrenceWithDetailsFragment";
};
export type OccurrenceWithDetailsFragment$key = {
  readonly " $data"?: OccurrenceWithDetailsFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"OccurrenceWithDetailsFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "OccurrenceWithDetailsFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "datetimeRange",
      "storageKey": null
    }
  ],
  "type": "Occurrence",
  "abstractKey": null
};

(node as any).hash = "6b2b452e67d186853e82cd5bfd3fa106";

export default node;
