/**
 * @generated SignedSource<<c691b5de1b3ff97601738ceb5e76d274>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type NavSectionKind = "products" | "custom" | "%future added value";
export type ProductStatus = "draft" | "published" | "archived" | "unlisted" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type NavSectionFragment$data = {
  readonly id: string;
  readonly kind: NavSectionKind;
  readonly title: string;
  readonly apps: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
      };
    }>;
  };
  readonly products: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly slug: string;
        readonly status: ProductStatus;
      };
    }>;
  };
  readonly " $fragmentSpreads": FragmentRefs<"UpdateNavSectionButtonFragment" | "DeleteNavSectionButtonFragment" | "ProductsSidebarList_NavSectionFragment" | "AppsSidebarList_NavSectionFragment">;
  readonly " $fragmentType": "NavSectionFragment";
};
export type NavSectionFragment$key = {
  readonly " $data"?: NavSectionFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"NavSectionFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "NavSectionFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "kind",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ProductAppNodeConnection",
      "kind": "LinkedField",
      "name": "apps",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ProductAppNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ProductApp",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ProductNodeConnection",
      "kind": "LinkedField",
      "name": "products",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ProductNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Product",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "slug",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "status",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "UpdateNavSectionButtonFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DeleteNavSectionButtonFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ProductsSidebarList_NavSectionFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AppsSidebarList_NavSectionFragment"
    }
  ],
  "type": "NavSection",
  "abstractKey": null
};
})();

(node as any).hash = "09e7fd81781a62ab60c567e306b3d7c4";

export default node;
