import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { NavSectionSelectQuery } from "@/organization/common/sidebar/nav-section/__generated__/NavSectionSelectQuery.graphql"
import { GlobalID } from "@/relay/RelayTypes"
import Relay from "@/relay/relayUtils"
import { DiscoSelect, DiscoSelectSkeleton } from "@disco-ui"
import { TestIDProps } from "@utils/typeUtils"
import { graphql, useLazyLoadQuery } from "react-relay"

type Props = TestIDProps & {
  value: GlobalID | null | undefined
  onChange: (selectedId: GlobalID | null) => void
}

function NavSectionSelect(props: Props) {
  const { value, onChange, testid = "NavSectionSelect" } = props
  const activeOrganization = useActiveOrganization()!

  const { organization } = useLazyLoadQuery<NavSectionSelectQuery>(
    graphql`
      query NavSectionSelectQuery($id: ID!) {
        organization: node(id: $id) {
          ... on Organization {
            navSections(kind: custom) {
              edges {
                node {
                  id
                  title
                }
              }
            }
          }
        }
      }
    `,
    { id: activeOrganization.id },
    { fetchPolicy: "store-and-network" }
  )
  const navSections = Relay.connectionToArray(organization?.navSections)

  return (
    <DiscoSelect
      testid={testid}
      value={value}
      onChange={onChange}
      options={navSections.map((ns) => ({ value: ns.id, title: ns.title }))}
      placeholder={"No section"}
    />
  )
}

export default Relay.withSkeleton({
  component: NavSectionSelect,
  skeleton: DiscoSelectSkeleton,
})
