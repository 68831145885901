/**
 * @generated SignedSource<<392e638a998128063fb3f515a42431d1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type OrganizationMemberMultiSelect_PaginationQuery$variables = {
  id: string;
  first: number;
  after?: string | null;
  productIds?: ReadonlyArray<string> | null;
  search?: string | null;
  selectedIds?: ReadonlyArray<string> | null;
  inProductsViewerIsAdminOf?: boolean | null;
};
export type OrganizationMemberMultiSelect_PaginationQuery$data = {
  readonly node: {
    readonly organizationMemberships?: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
          readonly email: string | null;
          readonly member: {
            readonly id: string;
            readonly fullName: string;
            readonly " $fragmentSpreads": FragmentRefs<"UserDropdownItemFragment" | "ProfileAvatarWithDetailsFragment">;
          };
          readonly " $fragmentSpreads": FragmentRefs<"UserDropdownItemPrivateFragment">;
        };
      }>;
      readonly pageInfo: {
        readonly hasNextPage: boolean;
        readonly hasPreviousPage: boolean;
        readonly startCursor: string | null;
        readonly endCursor: string | null;
      };
    };
    readonly selected?: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
          readonly email: string | null;
          readonly member: {
            readonly id: string;
            readonly fullName: string;
            readonly " $fragmentSpreads": FragmentRefs<"UserDropdownItemFragment" | "ProfileAvatarWithDetailsFragment">;
          };
          readonly " $fragmentSpreads": FragmentRefs<"UserDropdownItemPrivateFragment">;
        };
      }>;
    };
  } | null;
};
export type OrganizationMemberMultiSelect_PaginationQuery = {
  variables: OrganizationMemberMultiSelect_PaginationQuery$variables;
  response: OrganizationMemberMultiSelect_PaginationQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "after"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "inProductsViewerIsAdminOf"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "productIds"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "search"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "selectedIds"
},
v7 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v8 = {
  "kind": "Variable",
  "name": "excludeOrganizationMembershipIds",
  "variableName": "selectedIds"
},
v9 = {
  "kind": "Variable",
  "name": "inProductsViewerIsAdminOf",
  "variableName": "inProductsViewerIsAdminOf"
},
v10 = {
  "kind": "Variable",
  "name": "productIds",
  "variableName": "productIds"
},
v11 = {
  "kind": "Variable",
  "name": "search",
  "variableName": "search"
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v14 = {
  "args": null,
  "kind": "FragmentSpread",
  "name": "UserDropdownItemPrivateFragment"
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "fullName",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "concreteType": "User",
  "kind": "LinkedField",
  "name": "member",
  "plural": false,
  "selections": [
    (v12/*: any*/),
    (v15/*: any*/),
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "UserDropdownItemFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ProfileAvatarWithDetailsFragment"
    }
  ],
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cursor",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "concreteType": "PageInfoObjectType",
  "kind": "LinkedField",
  "name": "pageInfo",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasNextPage",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasPreviousPage",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "startCursor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endCursor",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v20 = [
  {
    "kind": "Variable",
    "name": "organizationMembershipIds",
    "variableName": "selectedIds"
  }
],
v21 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after"
  },
  (v8/*: any*/),
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  (v9/*: any*/),
  (v10/*: any*/),
  (v11/*: any*/)
],
v22 = {
  "alias": null,
  "args": null,
  "concreteType": "User",
  "kind": "LinkedField",
  "name": "member",
  "plural": false,
  "selections": [
    (v12/*: any*/),
    (v15/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "avatar",
      "storageKey": null
    },
    {
      "alias": "first_name",
      "args": null,
      "kind": "ScalarField",
      "name": "firstName",
      "storageKey": null
    },
    {
      "alias": "last_name",
      "args": null,
      "kind": "ScalarField",
      "name": "lastName",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v23 = {
  "kind": "TypeDiscriminator",
  "abstractKey": "__isPrivateUser"
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "OrganizationMemberMultiSelect_PaginationQuery",
    "selections": [
      {
        "alias": null,
        "args": (v7/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": "organizationMemberships",
                "args": [
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/)
                ],
                "concreteType": "OrganizationMembershipNodeConnection",
                "kind": "LinkedField",
                "name": "__OrganizationMemberMultiSelect_organizationMemberships_connection",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "OrganizationMembershipNodeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "OrganizationMembership",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v12/*: any*/),
                          (v13/*: any*/),
                          (v14/*: any*/),
                          (v16/*: any*/),
                          (v17/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v18/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v19/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": "selected",
                "args": (v20/*: any*/),
                "concreteType": "OrganizationMembershipNodeConnection",
                "kind": "LinkedField",
                "name": "organizationMemberships",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "OrganizationMembershipNodeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "OrganizationMembership",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v12/*: any*/),
                          (v13/*: any*/),
                          (v14/*: any*/),
                          (v16/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "Organization",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Operation",
    "name": "OrganizationMemberMultiSelect_PaginationQuery",
    "selections": [
      {
        "alias": null,
        "args": (v7/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v17/*: any*/),
          (v12/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": (v21/*: any*/),
                "concreteType": "OrganizationMembershipNodeConnection",
                "kind": "LinkedField",
                "name": "organizationMemberships",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "OrganizationMembershipNodeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "OrganizationMembership",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v12/*: any*/),
                          (v13/*: any*/),
                          (v22/*: any*/),
                          (v17/*: any*/),
                          (v23/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v18/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v19/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v21/*: any*/),
                "filters": [
                  "productIds",
                  "search",
                  "excludeOrganizationMembershipIds",
                  "inProductsViewerIsAdminOf"
                ],
                "handle": "connection",
                "key": "OrganizationMemberMultiSelect_organizationMemberships",
                "kind": "LinkedHandle",
                "name": "organizationMemberships"
              },
              {
                "alias": "selected",
                "args": (v20/*: any*/),
                "concreteType": "OrganizationMembershipNodeConnection",
                "kind": "LinkedField",
                "name": "organizationMemberships",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "OrganizationMembershipNodeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "OrganizationMembership",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v12/*: any*/),
                          (v13/*: any*/),
                          (v22/*: any*/),
                          (v23/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "Organization",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "3c67e2ab5cafe3a0487b5dbbba8c540d",
    "id": null,
    "metadata": {
      "connection": [
        {
          "count": "first",
          "cursor": "after",
          "direction": "forward",
          "path": [
            "node",
            "organizationMemberships"
          ]
        }
      ]
    },
    "name": "OrganizationMemberMultiSelect_PaginationQuery",
    "operationKind": "query",
    "text": "query OrganizationMemberMultiSelect_PaginationQuery(\n  $id: ID!\n  $first: Int!\n  $after: String\n  $productIds: [ID!]\n  $search: String\n  $selectedIds: [ID!]\n  $inProductsViewerIsAdminOf: Boolean\n) {\n  node(id: $id) {\n    __typename\n    ... on Organization {\n      organizationMemberships(after: $after, first: $first, productIds: $productIds, search: $search, excludeOrganizationMembershipIds: $selectedIds, inProductsViewerIsAdminOf: $inProductsViewerIsAdminOf) {\n        edges {\n          node {\n            id\n            email\n            ...UserDropdownItemPrivateFragment\n            member {\n              id\n              fullName\n              ...UserDropdownItemFragment\n              ...ProfileAvatarWithDetailsFragment\n            }\n            __typename\n          }\n          cursor\n        }\n        pageInfo {\n          hasNextPage\n          hasPreviousPage\n          startCursor\n          endCursor\n        }\n      }\n      selected: organizationMemberships(organizationMembershipIds: $selectedIds) {\n        edges {\n          node {\n            id\n            email\n            ...UserDropdownItemPrivateFragment\n            member {\n              id\n              fullName\n              ...UserDropdownItemFragment\n              ...ProfileAvatarWithDetailsFragment\n            }\n          }\n        }\n      }\n    }\n    id\n  }\n}\n\nfragment ProfileAvatarFragment on User {\n  id\n  fullName\n  avatar\n}\n\nfragment ProfileAvatarWithDetailsFragment on User {\n  id\n  fullName\n  ...ProfileAvatarFragment\n}\n\nfragment UserDropdownItemFragment on User {\n  id\n  avatar\n  fullName\n  first_name: firstName\n  last_name: lastName\n}\n\nfragment UserDropdownItemPrivateFragment on PrivateUser {\n  __isPrivateUser: __typename\n  email\n}\n"
  }
};
})();

(node as any).hash = "e1c426cb35ac5dae3249334ec3c12d8d";

export default node;
