import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { useLabel } from "@/core/context/LabelsContext"
import { EventsProductsFilterDropdownQuery } from "@/occurrence/sidebar/__generated__/EventsProductsFilterDropdownQuery.graphql"
import { EventsCalendarQueryParams } from "@/organization/occurrence/OrganizationOccurrenceList"
import Relay from "@/relay/relayUtils"
import { DiscoButtonSkeleton, DiscoIcon } from "@disco-ui"
import DiscoTableFilterDropdown from "@disco-ui/table/header/search/DiscoTableFilterDropdown"
import { useQueryParamState } from "@disco-ui/tabs/DiscoQueryParamTabs"
import { graphql, useLazyLoadQuery } from "react-relay"

function EventsProductsFilterDropdown() {
  const activeOrganization = useActiveOrganization()!
  const [params, setParams] = useQueryParamState<EventsCalendarQueryParams>()

  const experienceLabel = useLabel("experience")

  const filteredProductIds = params.productIds ? params.productIds.split(",") : []

  const { organization } = useLazyLoadQuery<EventsProductsFilterDropdownQuery>(
    graphql`
      query EventsProductsFilterDropdownQuery($id: ID!) {
        organization: node(id: $id) {
          ... on Organization {
            id
            products(type: "course") {
              edges {
                node {
                  id
                  name
                }
              }
            }
          }
        }
      }
    `,
    {
      id: activeOrganization.id,
    }
  )

  const products = Relay.connectionToArray(organization?.products)

  const options = [
    {
      value: null,
      title: `All ${experienceLabel.plural}`,
      testid: "products-all",
    },
    ...products.map((product) => ({
      value: product.id,
      title: product.name,
      testid: `product-${product.name}`,
    })),
  ]

  return (
    <DiscoTableFilterDropdown
      isNested
      testid={"EventFilter.products"}
      options={options}
      onOptionSelect={(option) => {
        if (!option || !option.value) {
          setParams({ productIds: undefined })
          return
        }

        if (filteredProductIds.includes(option.value)) {
          setParams({
            productIds: filteredProductIds.filter((id) => id !== option.value).join(","),
          })
          return
        }

        setParams({ productIds: [...filteredProductIds, option.value].join(",") })
      }}
      selectedOption={products
        .filter((product) => filteredProductIds.includes(product.id))
        .map((product) => ({
          value: product.id,
          title: product.name,
          testid: `product-${product.name}`,
        }))}
      dropdown={{
        icon: <DiscoIcon icon={"book"} />,
        label: experienceLabel.singular,
      }}
    />
  )
}

function EventsProductsFilterDropdownSkeleton() {
  return <DiscoButtonSkeleton />
}

export default Relay.withSkeleton({
  component: EventsProductsFilterDropdown,
  skeleton: EventsProductsFilterDropdownSkeleton,
})
