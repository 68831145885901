import {
  EventsCalendarQueryParams,
  EventsDateFilterOption,
} from "@/organization/occurrence/OrganizationOccurrenceList"
import { DiscoIcon } from "@disco-ui"
import DiscoTableFilterDropdown from "@disco-ui/table/header/search/DiscoTableFilterDropdown"
import { useQueryParamState } from "@disco-ui/tabs/DiscoQueryParamTabs"
import { capitalize } from "lodash"

type DateFilterOption = {
  value: EventsDateFilterOption
  title: string
}

function EventsStatusFilterDropdown() {
  const [params, setParams] = useQueryParamState<EventsCalendarQueryParams>()

  const options: DateFilterOption[] = [
    {
      value: "upcoming",
      title: "Upcoming",
    },
    {
      value: "past",
      title: "Past",
    },
  ]

  return (
    <DiscoTableFilterDropdown
      isNested
      testid={"EventFilter.status"}
      options={options}
      onOptionSelect={(option) => {
        if (!option) return
        if (params.dateFilter === option.value) {
          setParams({ dateFilter: undefined })
          return
        }
        setParams({ dateFilter: option.value })
      }}
      selectedOption={
        params.dateFilter
          ? {
              value: params.dateFilter,
              title: capitalize(params.dateFilter),
            }
          : undefined
      }
      dropdown={{
        icon: <DiscoIcon icon={"check-circle"} />,
        label: "Status",
      }}
    />
  )
}

export default EventsStatusFilterDropdown
