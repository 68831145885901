import ClockIcon from "@/core/ui/iconsax/linear/clock-1.svg"
import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import { useOccurrenceStatus } from "@/occurrence/util/occurrenceUtils"
import { OccurrenceStatusChipFragment$key } from "@/organization/occurrence/__generated__/OccurrenceStatusChipFragment.graphql"
import Relay from "@/relay/relayUtils"
import PulsatingDot from "@components/pulsating-dot/PulsatingDot"
import { DiscoChip, DiscoChipSkeleton, DiscoText } from "@disco-ui"
import useCountDownTimer, { countdownDataToMessage } from "@utils/hook/useCountDownTimer"
import classNames from "classnames"
import { graphql, useFragment } from "react-relay"

type OccurrenceStatusChipProps = {
  occurrenceKey: OccurrenceStatusChipFragment$key
  className?: string
}

function OccurrenceStatusChip({
  occurrenceKey,
  className: customClassName,
}: OccurrenceStatusChipProps) {
  const occurrence = useFragment<OccurrenceStatusChipFragment$key>(
    graphql`
      fragment OccurrenceStatusChipFragment on Occurrence {
        id
        datetimeRange
        ...occurrenceUtils_useOccurrenceStatusFragment
      }
    `,
    occurrenceKey
  )
  const status = useOccurrenceStatus(occurrence)
  const disabled = status !== "live-in-hour"
  const startsAt = new Date(occurrence.datetimeRange[0])
  // Only count down when starting in < 1 hour to avoid constant laggy state updates.
  const startCountdown = useCountDownTimer(startsAt, {
    cadenceSecs: 1,
    disabled,
  })

  const classes = useStyles()

  switch (status) {
    case "live":
    case "register-while-live":
    case "rsvp-while-live":
      return (
        <div className={classNames(classes.container, customClassName)}>
          <DiscoText color={"groovy.red.400"} marginRight={1} marginTop={-0.25}>
            {"Live Now"}
          </DiscoText>
          <PulsatingDot />
        </div>
      )
    case "live-in-hour":
      return (
        <div className={classNames(customClassName)}>
          <DiscoChip
            color={"yellow"}
            label={`Starts in ${countdownDataToMessage(startCountdown)}`}
            icon={<ClockIcon />}
          />
        </div>
      )
    default:
      return null
  }
}

function OccurrenceStatusChipSkeleton() {
  return <DiscoChipSkeleton />
}

const useStyles = makeUseStyles({
  container: {
    display: "flex",
    flexWrap: "nowrap",
  },
})

export default Relay.withSkeleton({
  skeleton: OccurrenceStatusChipSkeleton,
  component: OccurrenceStatusChip,
})
