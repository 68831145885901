import { useLabels } from "@/core/context/LabelsContext"
import FormStore from "@/core/form/store/FormStore"
import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import {
  OccurrenceMedia,
  OccurrenceMediaEmailRecipients,
} from "@/occurrence/api/occurrenceApiModels"
import { UploadEventVideoModalContentQuery } from "@/occurrence/live-box/__generated__/UploadEventVideoModalContentQuery.graphql"
import { CreateOccurrenceMediaInput } from "@/occurrence/live-box/__generated__/UploadEventVideoModalMutation.graphql"
import { GlobalID } from "@/relay/RelayTypes"
import Relay from "@/relay/relayUtils"
import FileDropzone, { VIDEO_FILE_TYPES } from "@components/dropzone/FileDropzone"
import Form from "@components/form/Form"
import { MediaResult } from "@components/media/upload/hooks/useMultipartUploadMediaToS3"
import { displaySuccessToast } from "@components/toast/ToastProvider"
import {
  DiscoDivider,
  DiscoFormControl,
  DiscoInput,
  DiscoRadio,
  DiscoSection,
  DiscoSwitch,
  DiscoText,
  DiscoTextSkeleton,
  DiscoTooltip,
} from "@disco-ui"
import DiscoVideo from "@disco-ui/video/DiscoVideo"
import { RadioGroup } from "@material-ui/core"
import { Skeleton } from "@material-ui/lab"
import { observer } from "mobx-react-lite"
import React, { useEffect } from "react"
import { graphql, useLazyLoadQuery } from "react-relay"

type Props = {
  form: FormStore<CreateOccurrenceMediaInput>
  occurrenceId: GlobalID
  setOccurrenceMedia?: React.Dispatch<React.SetStateAction<OccurrenceMedia | null>>
}

function UploadEventVideoModalContent({ form, occurrenceId, setOccurrenceMedia }: Props) {
  const { occurrence } = useLazyLoadQuery<UploadEventVideoModalContentQuery>(
    graphql`
      query UploadEventVideoModalContentQuery($id: ID!) {
        occurrence: node(id: $id) {
          ... on Occurrence {
            notAttendedBy {
              totalCount
            }
            attendees {
              totalCount
            }
            event {
              product {
                name
                type
              }
            }
          }
        }
      }
    `,
    { id: occurrenceId }
  )

  const product = occurrence?.event?.product
  const recipientCounts = tallyEmailRecipientBuckets()
  const classes = useStyles()
  const labels = useLabels()

  const memberLabel = labels.product_member

  useEffect(() => {
    form.state.notificationEmailBody = `A recording has been added to an event in ${product?.name}. If you missed this event or want to watch it again, click the button below.`
    form.state.notificationEmailRecipients =
      recipientCounts.not_attended > 0
        ? "not_attended"
        : recipientCounts.attended > 0
        ? "attended"
        : undefined
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Form id={"UploadEventVideoForm"} onSubmit={handleUploadEventVideo}>
      <DiscoText variant={"body-md-600"}>{"Event Recording"}</DiscoText>
      <DiscoText variant={"body-sm"} color={"text.secondary"} marginBottom={0.5}>
        {`Upload a recording of this event for ${labels.product_member.plural} who have missed it or want to watch it again.`}
      </DiscoText>

      {form.state.url ? (
        <div className={classes.videoContainer}>
          <DiscoVideo
            src={form.state.url}
            maxHeight={"150px"}
            testid={"UploadEventVideoModal.video-preview"}
          />
        </div>
      ) : (
        <FileDropzone
          dropzoneOptions={{ multiple: false, accept: VIDEO_FILE_TYPES }}
          allowedFileTypes={["video"]}
          onUpload={handleChangeMedia}
          onMediaSelect={handleChangeMedia}
          message={"Drop the recording here"}
          testid={`UploadEventVideoModal.video-upload`}
          showSupportedFiles={true}
        />
      )}

      {recipientCounts.all > 0 && (
        <>
          <DiscoSwitch
            label={"Share link to recording by email"}
            checked={form.state.sendNotificationEmail}
            onChange={(checked) => (form.state.sendNotificationEmail = checked)}
            data-testid={"UploadEventVideoModal.notifications-toggle"}
            classes={{ root: classes.notificationsToggle }}
            labelPlacement={"end"}
          />

          {form.state.sendNotificationEmail && (
            <DiscoSection groovyDepths={"insideCard"} padding={2.5}>
              <DiscoFormControl
                marginBottom={0}
                variant={"two-column"}
                description={"Select who you want to notify:"}
              >
                <RadioGroup
                  value={form.state.notificationEmailRecipients}
                  onChange={(e) =>
                    (form.state.notificationEmailRecipients = e.target
                      .value as OccurrenceMediaEmailRecipients)
                  }
                >
                  {wrapWithTooltip(
                    recipientCounts.not_attended === 0 || recipientCounts.attended === 0,
                    <DiscoRadio
                      data-testid={`UploadEventVideoModal.Radio.all`}
                      value={"all"}
                      label={`All ${recipientCounts.all} ${
                        recipientCounts.all === 1
                          ? memberLabel.singular
                          : memberLabel.plural
                      }`}
                      variant={"simple"}
                      disabled={
                        recipientCounts.not_attended === 0 ||
                        recipientCounts.attended === 0
                      }
                    />
                  )}
                  {wrapWithTooltip(
                    recipientCounts.not_attended === 0,
                    <DiscoRadio
                      data-testid={`UploadEventVideoModal.Radio.not-attended`}
                      value={"not_attended"}
                      label={`${recipientCounts.not_attended} ${
                        recipientCounts.not_attended === 1
                          ? memberLabel.singular
                          : memberLabel.plural
                      } that did NOT attend`}
                      variant={"simple"}
                      disabled={recipientCounts.not_attended === 0}
                    />
                  )}
                  {wrapWithTooltip(
                    recipientCounts.attended === 0,
                    <DiscoRadio
                      data-testid={`UploadEventVideoModal.Radio.attended`}
                      value={"attended"}
                      label={`${recipientCounts.attended} ${
                        recipientCounts.attended === 1
                          ? memberLabel.singular
                          : memberLabel.plural
                      } that attended`}
                      variant={"simple"}
                      disabled={recipientCounts.attended === 0}
                    />
                  )}
                </RadioGroup>
              </DiscoFormControl>
              <DiscoDivider />
              <DiscoFormControl
                label={
                  <DiscoText variant={"body-sm"} color={"text.secondary"}>
                    {"Email body"}
                  </DiscoText>
                }
              >
                <DiscoInput
                  multiline
                  value={form.state.notificationEmailBody}
                  onChange={(e) => (form.state.notificationEmailBody = e.target.value)}
                  minHeight={"100px"}
                />
              </DiscoFormControl>
            </DiscoSection>
          )}
        </>
      )}
    </Form>
  )

  function handleChangeMedia(result: MediaResult) {
    form.state.url = result.url
    form.state.mediaAssetId = result.id
  }

  async function handleUploadEventVideo() {
    // Can't upload video if it doesn't exist
    if (!form.state.url) return

    const { didSave, response } = await form.submit({
      ...form.state,
      notificationEmailBody: form.state.sendNotificationEmail
        ? form.state.notificationEmailBody
        : "",
      notificationEmailRecipients: form.state.sendNotificationEmail
        ? form.state.notificationEmailRecipients
        : undefined,
    })
    if (!didSave) return

    if (setOccurrenceMedia) {
      if (response?.node) {
        setOccurrenceMedia({
          id: Relay.rawId(response.node.id),
          kind: response.node.kind,
          url: response.node.url,
        })
      }
    }

    displaySuccessToast({
      message: "Recording uploaded!",
      testid: "UploadEventVideoModal.VideoUploaded.success-message",
    })
  }

  function tallyEmailRecipientBuckets(): Record<OccurrenceMediaEmailRecipients, number> {
    const attended = occurrence?.attendees?.totalCount || 0
    const not_attended = occurrence?.notAttendedBy?.totalCount || 0
    return {
      attended,
      not_attended,
      all: attended + not_attended,
    }
  }

  function wrapWithTooltip(condition: boolean, element: React.ReactElement) {
    const memberText =
      recipientCounts.all === 1 ? memberLabel.singular : memberLabel.plural
    const attendedText = recipientCounts.attended === 0 ? "did not attend" : "attended"
    return (
      <DiscoTooltip
        disabled={!condition}
        content={`All ${recipientCounts.all} ${memberText} ${attendedText}`}
      >
        <div className={classes.innerTooltip}>{element}</div>
      </DiscoTooltip>
    )
  }
}

const useStyles = makeUseStyles((theme) => ({
  notificationsToggle: {
    marginTop: theme.spacing(2.5),
    marginBottom: theme.spacing(2.5),
  },
  innerTooltip: {
    marginBottom: theme.spacing(1),
  },
  videoContainer: {
    display: "flex",
    justifyContent: "center",
  },
}))

function UploadEventVideoModalContentSkeleton() {
  return (
    <>
      <DiscoTextSkeleton width={"20%"} />
      <DiscoTextSkeleton />
      <Skeleton variant={"rect"} height={"200px"} />
      <DiscoTextSkeleton width={"40%"} />
    </>
  )
}

export default Relay.withSkeleton({
  component: observer(UploadEventVideoModalContent),
  skeleton: UploadEventVideoModalContentSkeleton,
})
