/**
 * @generated SignedSource<<c30fe9145487fc7dde8a92443ba281d4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type RSVPToEventInput = {
  occurrenceId?: string | null;
  eventId?: string | null;
  productId?: string | null;
};
export type RSVPToAllProductEventsButtonMutation$variables = {
  input: RSVPToEventInput;
};
export type RSVPToAllProductEventsButtonMutation$data = {
  readonly response: {
    readonly occurrences: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
          readonly hasViewerRSVPd: boolean;
          readonly atCapacity: boolean;
          readonly rsvps: {
            readonly totalCount: number;
          } | null;
        };
      }>;
    } | null;
    readonly errors: ReadonlyArray<{
      readonly field: string;
      readonly message: string;
    }> | null;
  };
};
export type RSVPToAllProductEventsButtonMutation = {
  variables: RSVPToAllProductEventsButtonMutation$variables;
  response: RSVPToAllProductEventsButtonMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": "response",
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "RSVPToEventResponse",
    "kind": "LinkedField",
    "name": "rsvpToEvent",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "OccurrenceNodeConnection",
        "kind": "LinkedField",
        "name": "occurrences",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "OccurrenceNodeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Occurrence",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasViewerRSVPd",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "atCapacity",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "OccurrenceRSVPNodeConnection",
                    "kind": "LinkedField",
                    "name": "rsvps",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "totalCount",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ValidationError",
        "kind": "LinkedField",
        "name": "errors",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "field",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "message",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RSVPToAllProductEventsButtonMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RSVPToAllProductEventsButtonMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "d8f36064cac4c34819ea084c94b1df6a",
    "id": null,
    "metadata": {},
    "name": "RSVPToAllProductEventsButtonMutation",
    "operationKind": "mutation",
    "text": "mutation RSVPToAllProductEventsButtonMutation(\n  $input: RSVPToEventInput!\n) {\n  response: rsvpToEvent(input: $input) {\n    occurrences {\n      edges {\n        node {\n          id\n          hasViewerRSVPd\n          atCapacity\n          rsvps {\n            totalCount\n          }\n        }\n      }\n    }\n    errors {\n      field\n      message\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "4e7e4dd36dcdda31e78512e672790a29";

export default node;
