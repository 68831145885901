import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { useAuthUser } from "@/core/context/AuthUserContext"
import { useLabel } from "@/core/context/LabelsContext"
import { CalendarConnectionPlatform } from "@/core/context/__generated__/AuthUserContextFragment.graphql"
import { useFormStore } from "@/core/form/store/FormStore"
import { RSVPToAllOrganizationEventsButtonMutation } from "@/occurrence/button/rsvp-to-all-button/__generated__/RSVPToAllOrganizationEventsButtonMutation.graphql"
import {
  RSVPToAllOrganizationEventsButtonQuery,
  RSVPToAllOrganizationEventsButtonQuery$variables,
} from "@/occurrence/button/rsvp-to-all-button/__generated__/RSVPToAllOrganizationEventsButtonQuery.graphql"
import {
  EventsCalendarQueryParams,
  EventsFilterOption,
  getEventFilters,
} from "@/organization/occurrence/OrganizationOccurrenceList"
import Relay from "@/relay/relayUtils"
import { displaySuccessToast } from "@components/toast/ToastProvider"
import { DiscoButtonSkeleton, DiscoTooltip } from "@disco-ui"
import {
  OverridableDiscoButton,
  OverridableDiscoButtonChildren,
} from "@disco-ui/button/OverridableDiscoButton"
import DiscoWarningModal from "@disco-ui/modal/DiscoWarningModal"
import { useQueryParamState } from "@disco-ui/tabs/DiscoQueryParamTabs"
import useDisclosure from "@utils/hook/useDisclosure"
import { useEffect } from "react"
import { useSubscribeToInvalidationState } from "react-relay"
import { graphql } from "relay-runtime"

interface RSVPToAllOrganizationEventsButtonProps {
  children: OverridableDiscoButtonChildren
  filter: EventsFilterOption
  showWhenDisabled?: boolean
  excludeProductEvents?: boolean
}

function RSVPToAllOrganizationEventsButton({
  children,
  filter,
  showWhenDisabled,
  excludeProductEvents,
}: RSVPToAllOrganizationEventsButtonProps) {
  const activeOrganization = useActiveOrganization()!
  const isOrgAdmin = activeOrganization?.viewerIsOwnerOrAdmin

  const { authUser } = useAuthUser({ required: true })

  const { isOpen, onClose, onOpen } = useDisclosure()

  const [params] = useQueryParamState<EventsCalendarQueryParams>()

  const productIds = params.productIds?.split(",") || []

  const experienceLabel = useLabel("admin_experience")

  const { includedProductIds, excludeCommunityEvents } = getEventFilters({
    filter,
    dateFilter: params.dateFilter,
    productIds,
  })

  const queryVariables: RSVPToAllOrganizationEventsButtonQuery$variables = {
    id: activeOrganization.id,
    excludeProductEvents,
    excludeCommunityEvents,
    includedProductIds,
  }

  const [{ organization }, refetch] =
    Relay.useRefetchableQuery<RSVPToAllOrganizationEventsButtonQuery>(
      graphql`
        query RSVPToAllOrganizationEventsButtonQuery(
          $id: ID!
          $excludeProductEvents: Boolean
          $excludeCommunityEvents: Boolean
          $includedProductIds: [ID!]
        ) {
          organization: node(id: $id) {
            ... on Organization {
              id
              occurrences(
                first: 1
                viewerHasRSVP: false
                atCapacity: false
                datetimeFilter: upcoming
                status: published
                productStatus: published
                requireProductMembership: true
                excludeProductEvents: $excludeProductEvents
                excludeCommunityEvents: $excludeCommunityEvents
                includedProductIds: $includedProductIds
              ) @connection(key: "RSVPToAllOrganizationEventsButton_occurrences") {
                __id
                totalCount
                # Edges required for @connection directive
                edges {
                  node {
                    id
                  }
                }
              }
            }
          }
        }
      `,
      queryVariables
    )

  useSubscribeToInvalidationState([organization?.occurrences?.__id ?? ""], () => {
    if (!organization?.occurrences) return
    refetch(queryVariables, { inBackground: true })
  })

  useEffect(() => {
    refetch(queryVariables, { inBackground: true })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.productIds])

  const form = useFormStore<RSVPToAllOrganizationEventsButtonMutation>(
    graphql`
      mutation RSVPToAllOrganizationEventsButtonMutation($input: RSVPToEventsInput!) {
        response: rsvpToEvents(input: $input) {
          occurrences {
            edges {
              node {
                id
                hasViewerRSVPd
                atCapacity
                rsvps {
                  totalCount
                }
              }
            }
          }
          errors {
            field
            message
          }
        }
      }
    `,
    {
      organizationId: activeOrganization.id,
      excludeProductEvents,
      excludeCommunityEvents,
      includedProductIds,
    }
  )

  if (!organization?.occurrences) return null

  const testid = "RSVPToAllOrganizationEventsButton"

  // Hide button if user is RSVPd to all upcoming events available or all events are at capacity
  const userRSVPdToAllFutureEvents = organization.occurrences.totalCount === 0

  const shouldButtonBeDisabled = userRSVPdToAllFutureEvents

  if (shouldButtonBeDisabled && !showWhenDisabled) return null

  return (
    <>
      {shouldButtonBeDisabled ? (
        showWhenDisabled && (
          <DiscoTooltip content={getButtonDisabledReason()}>
            <span>
              <OverridableDiscoButton disabled>{children}</OverridableDiscoButton>
            </span>
          </DiscoTooltip>
        )
      ) : (
        <OverridableDiscoButton onClick={onOpen}>{children}</OverridableDiscoButton>
      )}
      {isOpen && (
        <DiscoWarningModal
          testid={testid}
          isOpen
          variant={"primary"}
          confirmationButtonProps={{
            onClick: handleSubmit,
            shouldDisplaySpinner: form.isSubmitting,
            children: "Attend All",
          }}
          onClose={onClose}
          modalContentLabel={"Confirm attendance to all dialog"}
          title={"Confirm Attendance to All Events?"}
          description={getRSVPModalDescription(authUser.calendarConnection?.platform)}
          icon={"calendar"}
        />
      )}
    </>
  )

  async function handleSubmit() {
    const { didSave } = await form.submit({
      organizationId: activeOrganization.id,
      excludeProductEvents,
      excludeCommunityEvents,
      includedProductIds,
    })

    if (!didSave) return

    displaySuccessToast({
      message:
        "You confirmed attendance to all events. Check your email for more details!",
      testid: `${testid}.success-toast`,
    })

    refetch(queryVariables, { inBackground: true })

    onClose()
  }

  function getButtonDisabledReason() {
    if (userRSVPdToAllFutureEvents) {
      if (isOrgAdmin) {
        if (includedProductIds && includedProductIds.length > 0) {
          return `You are already attending all published events for selected ${experienceLabel.plural} you are enrolled in.`
        }
        return "You are already attending all published events!"
      }
      return "You are already attending all events!"
    }
    return ""
  }
}

export function getRSVPModalDescription(platform?: CalendarConnectionPlatform) {
  switch (platform) {
    case "outlook":
      return "You will receive a confirmation email and the events will be added to your Outlook calendar. A reminder email will also be sent ahead of each event."
    default:
      return "You will receive an email with Calendar Files (.ICS) to be added to your calendar. A reminder email will also be sent ahead of each event."
  }
}

export default Relay.withSkeleton<RSVPToAllOrganizationEventsButtonProps>({
  component: RSVPToAllOrganizationEventsButton,
  skeleton: () => <DiscoButtonSkeleton width={"130px"} />,
})
