import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { useActiveProduct } from "@/core/context/ActiveProductContext"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import useShowOnHoverStyles from "@assets/style/util/useShowOnHoverStyles"
import NotificationBadge, {
  NotificationBadgeProps,
} from "@components/square-count-badge/NotificationBadge"
import { DiscoIcon, DiscoSideBarItem, DiscoSideBarItemProps } from "@disco-ui"
import DiscoMoreActionsDropdown from "@disco-ui/dropdown/DiscoMoreActionsDropdown"
import { useTheme } from "@material-ui/core"
import usePermissions from "@utils/hook/usePermissions"
import classnames from "classnames"

export type CommunitySideBarItemProps = DiscoSideBarItemProps & {
  overflowItems?: React.ReactNode
  showOnHoverRightContent?: React.ReactNode
  notificationConfig?: NotificationBadgeProps["config"]
}

function CommunitySidebarItem({
  className,
  overflowItems,
  /** Config for notification badge */
  notificationConfig,
  /** Right side content that is shown by default and hidden when the item is hovered over */
  rightContent,
  /** Right side content that is only shown when the item is collapsed */
  showOnCollapsedRightContent,
  /** Right side content that is only shown when the item is hovered over */
  showOnHoverRightContent,
  leftIcon,
  isDragging,
  showItemsRoute,
  ...rest
}: CommunitySideBarItemProps) {
  const classes = useStyles()
  const theme = useTheme()
  const activeProduct = useActiveProduct()
  const activeOrganization = useActiveOrganization()
  const permissions = usePermissions(activeProduct || activeOrganization)
  const canReorder = permissions.has("apps.manage")
  const showOnHoverClasses = useShowOnHoverStyles({
    hideWithDisplay: { stylesWhenShown: { display: "flex" } },
  })
  const hasHoverRightContent = Boolean(showOnHoverRightContent || overflowItems)
  // Don't show the re-order icon if draggable prop is not provided
  const draggable = typeof isDragging === "boolean"

  return (
    <DiscoSideBarItem
      {...rest}
      showItemsRoute={showItemsRoute}
      className={classnames(showOnHoverClasses.hoverable, className)}
      isDragging={isDragging}
      leftIcon={renderLeftIcon()}
      rightContent={rightContent}
      showOnHoverRightContent={
        hasHoverRightContent && (
          <>
            {showOnHoverRightContent}
            {overflowItems && (
              <DiscoMoreActionsDropdown
                dropdownContainerClassName={classes.dropdownContainer}
                moreActionsButtonClassName={classnames(
                  showOnHoverClasses.showable,
                  classes.moreActionsButton
                )}
                testid={`${rest.testid}.more-actions`}
                top={theme.spacing(3.5)}
                menuButtonProps={{ size: "small" }}
              >
                {overflowItems}
              </DiscoMoreActionsDropdown>
            )}
          </>
        )
      }
      showOnCollapsedRightContent={
        <>
          {showOnCollapsedRightContent}
          {notificationConfig && (
            <NotificationBadge
              testid={rest.testid}
              config={notificationConfig}
              aria-label={"Unread notifications"}
            />
          )}
        </>
      }
    />
  )

  function renderLeftIcon() {
    if (!leftIcon || !canReorder || typeof leftIcon === "string") return leftIcon
    if (!draggable) return leftIcon
    if (isDragging) return <DiscoIcon icon={"reorder"} width={16} height={16} />
    return (
      <>
        <span className={showOnHoverClasses.hidable}>{leftIcon}</span>
        <DiscoIcon
          icon={"reorder"}
          className={showOnHoverClasses.showable}
          width={16}
          height={16}
        />
      </>
    )
  }
}

const useStyles = makeUseStyles({
  moreActionsButton: {
    "&:hover": {
      background: "rgba(0,0,0,.1)",
    },
  },
  dropdownContainer: {
    maxWidth: "250px",
  },
})

export default CommunitySidebarItem
