import AddAppButton from "@/apps/add-app-button/AddAppButton"
import CreateNavSectionButton from "@/organization/common/sidebar/nav-section/CreateNavSectionButton"
import { DiscoDropdown, DiscoDropdownProps } from "@disco-ui"
import DiscoDropdownItem from "@disco-ui/dropdown/DiscoDropdownItem"

type Props = {
  children: DiscoDropdownProps["menuButton"]
}

export default function CommunitySidebarAddButton({ children }: Props) {
  return (
    <DiscoDropdown
      menuButton={children}
      horizontal={"center"}
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      mountChildrenOnOpen
    >
      <AddAppButton>
        {({ onClick }) => (
          <DiscoDropdownItem
            testid={"CommunitySidebarAddButton.app"}
            onClick={onClick}
            title={"Add App"}
          />
        )}
      </AddAppButton>
      <CreateNavSectionButton navSectionIndex={0}>
        {({ onClick }) => (
          <DiscoDropdownItem
            testid={"CommunitySidebarAddButton.nav-section"}
            onClick={onClick}
            title={"Add Section"}
          />
        )}
      </CreateNavSectionButton>
    </DiscoDropdown>
  )
}
