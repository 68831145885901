import { useGlobalDrawer } from "@/core/context/GlobalDrawerProvider"
import MediaLibraryButton from "@/media/add/MediaLibraryButton"
import { OccurrenceMedia } from "@/occurrence/api/occurrenceApiModels"
import UploadEventVideoModal from "@/occurrence/live-box/UploadEventVideoModal"
import { GlobalID } from "@/relay/RelayTypes"
import { VIDEO_FILE_TYPES } from "@components/dropzone/FileDropzone"
import { MediaResult } from "@components/media/upload/hooks/useMultipartUploadMediaToS3"
import {
  OverridableDiscoButton,
  OverridableDiscoButtonChildren,
} from "@disco-ui/button/OverridableDiscoButton"
import useFeatureFlags from "@utils/hook/useFeatureFlags"
import React, { useState } from "react"

interface Props {
  occurrenceId: GlobalID
  setOccurrenceMedia?: React.Dispatch<React.SetStateAction<OccurrenceMedia | null>>
  children: OverridableDiscoButtonChildren
}

const UploadEventVideoButton: React.FC<Props> = ({
  occurrenceId,
  setOccurrenceMedia,
  children,
}) => {
  const eventDrawer = useGlobalDrawer("event")
  const { videoTranscription } = useFeatureFlags()
  const [showModal, setShowModal] = useState(false)

  // If video transcription is enabled, enable inline video upload
  if (videoTranscription) {
    return (
      <MediaLibraryButton
        onMediaSelect={handleUploadedRecording}
        onUpload={handleUploadedRecording}
        dropzoneOptions={{
          accept: VIDEO_FILE_TYPES,
        }}
        allowedFileTypes={["video"]}
      >
        {children}
      </MediaLibraryButton>
    )
  }

  return (
    <>
      <OverridableDiscoButton
        onClick={handleClick}
        data-testid={"UploadEventVideoButton.button"}
      >
        {children}
      </OverridableDiscoButton>

      {showModal && (
        <UploadEventVideoModal
          isOpen={showModal}
          onClose={() => setShowModal(false)}
          setOccurrenceMedia={setOccurrenceMedia}
          occurrenceId={occurrenceId}
        />
      )}
    </>
  )

  function handleUploadedRecording(result: MediaResult) {
    eventDrawer.open({
      drawerOccurrenceId: occurrenceId,
      drawerEventTab: "details",
      temporaryRecordingAssetId: result.id,
    })
  }

  function handleClick(e: React.MouseEvent<HTMLButtonElement>) {
    // Stop event drawer from opening when inside OccurrenceCard
    e.preventDefault()
    e.stopPropagation()

    setShowModal(true)
  }
}

export default UploadEventVideoButton
