/**
 * @generated SignedSource<<f10cf20792bd21d7c6452425a40014a2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type CreateEventFeedbackInput = {
  occurrenceId: string;
  instructorRating?: number | null;
  videoQualityRating?: number | null;
  contentRating?: number | null;
  comments?: string | null;
  anonymous: boolean;
};
export type EventFeedbackFormMutation$variables = {
  input: CreateEventFeedbackInput;
};
export type EventFeedbackFormMutation$data = {
  readonly response: {
    readonly node: {
      readonly id: string;
    } | null;
    readonly errors: ReadonlyArray<{
      readonly field: string;
      readonly message: string;
    }> | null;
  };
};
export type EventFeedbackFormMutation = {
  variables: EventFeedbackFormMutation$variables;
  response: EventFeedbackFormMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": "response",
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreateEventFeedbackResponse",
    "kind": "LinkedField",
    "name": "submitEventFeedback",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "EventFeedback",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ValidationError",
        "kind": "LinkedField",
        "name": "errors",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "field",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "message",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EventFeedbackFormMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EventFeedbackFormMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "b6753ffaf461fd90d8dae5202d48ac50",
    "id": null,
    "metadata": {},
    "name": "EventFeedbackFormMutation",
    "operationKind": "mutation",
    "text": "mutation EventFeedbackFormMutation(\n  $input: CreateEventFeedbackInput!\n) {\n  response: submitEventFeedback(input: $input) {\n    node {\n      id\n    }\n    errors {\n      field\n      message\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "7a3960179196f12cab731a06edd1d56b";

export default node;
