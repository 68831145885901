import { useAuthUser } from "@/core/context/AuthUserContext"
import { Dispatch, SetStateAction, useEffect, useState } from "react"

type useLocalStorageStateProps<T> = {
  key: string
  defaultValue: T
  propertyName?: string
}

function useLocalStorageState<T = any>({
  key,
  defaultValue,
  propertyName,
}: useLocalStorageStateProps<T>): [T, Dispatch<SetStateAction<T>>] {
  const { authUser } = useAuthUser()
  const userSpecificKey = `${key}.${authUser ? authUser.id : "unauthenticated"}`

  const [state, setState] = useState(() => {
    // get state from local storage
    const localStorageState = localStorage.getItem(userSpecificKey)
    if (!localStorageState) return defaultValue
    const parsed = JSON.parse(localStorageState)
    if (!propertyName) return parsed as T
    return parsed[propertyName] === undefined ? defaultValue : (parsed[propertyName] as T)
  })

  useEffect(() => {
    // set state in local storage, removing the value if it's equivalent to the default
    let storageValue
    if (propertyName) {
      const currentValue = localStorage.getItem(userSpecificKey)
      storageValue = currentValue ? JSON.parse(currentValue) : {}
      if (state === defaultValue) {
        delete storageValue[propertyName]
      } else {
        storageValue[propertyName] = state
      }
    } else {
      if (state === defaultValue) {
        localStorage.removeItem(userSpecificKey)
        return
      }
      storageValue = state
    }
    try {
      localStorage.setItem(userSpecificKey, JSON.stringify(storageValue))
    } catch (err) {
      handleLocalStorageError(err)
    }
  }, [userSpecificKey, state, propertyName, defaultValue])

  return [state, setState]
}

export default useLocalStorageState

export function handleLocalStorageError(error: unknown) {
  // Log errors when setting local storage values, and clear storage if quota was exceeded
  console.error(error)
  if (error instanceof Error && error.name === "QuotaExceededError") {
    localStorage.clear()
  }
}
