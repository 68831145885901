import { useFormStore } from "@/core/form/store/FormStore"
import EventFeedbackFormFields, {
  EventFeedbackFormFieldsSkeleton,
} from "@/occurrence/feedback/form/fields/EventFeedbackFormFields"
import { EventFeedbackFormMutation } from "@/occurrence/feedback/form/__generated__/EventFeedbackFormMutation.graphql"
import { GlobalID } from "@/relay/RelayTypes"
import Relay from "@/relay/relayUtils"
import Form from "@components/form/Form"
import { displaySuccessToast } from "@components/toast/ToastProvider"
import { DiscoButton, DiscoTextSkeleton } from "@disco-ui"
import { observer } from "mobx-react-lite"
import { graphql } from "relay-runtime"

interface EventFeedbackFormProps {
  testid: string
  occurrenceId: GlobalID
  onSubmitFeedback: () => void
  onClose: () => void
  previewMode?: boolean
}

const EventFeedbackForm = observer<EventFeedbackFormProps>(
  ({ testid, occurrenceId, onSubmitFeedback, onClose, previewMode }) => {
    const form = useFormStore<EventFeedbackFormMutation>(
      graphql`
        mutation EventFeedbackFormMutation($input: CreateEventFeedbackInput!) {
          response: submitEventFeedback(input: $input) {
            node {
              id
            }
            errors {
              field
              message
            }
          }
        }
      `,
      {
        occurrenceId,
        contentRating: 0,
        instructorRating: 0,
        videoQualityRating: 0,
        anonymous: false,
      }
    )
    return (
      <Form
        testid={testid}
        onSubmit={handleSubmit}
        isSubmitAllowed={!previewMode}
        buttons={
          <>
            <DiscoButton color={"grey"} variant={"outlined"} onClick={onClose}>
              {"Skip"}
            </DiscoButton>
            <Form.SubmitButton
              testid={`${testid}.submit-button`}
              form={form}
              width={"160px"}
              disabled={previewMode || form.disabled}
            >
              {"Submit Feedback"}
            </Form.SubmitButton>
          </>
        }
      >
        <EventFeedbackFormFields form={form} testid={testid} />
      </Form>
    )

    async function handleSubmit() {
      const { didSave } = await form.submit(form.state)

      if (!didSave) return

      displaySuccessToast({
        message: "Feedback sent!",
        testid: `${testid}.submitted-emoji`,
      })

      onSubmitFeedback()
    }
  }
)

const EventFeedbackFormSkeleton = () => {
  return (
    <Form
      testid={"EventFeedbackFormSkeleton"}
      onSubmit={() => {
        return false
      }}
    >
      <DiscoTextSkeleton variant={"heading-md"} />
      <EventFeedbackFormFieldsSkeleton />
    </Form>
  )
}

export default Relay.withSkeleton({
  component: EventFeedbackForm,
  skeleton: EventFeedbackFormSkeleton,
})
