import FormStore from "@/core/form/store/FormStore"
import {
  UpdateNavSectionButtonMutation,
  UpdateNavSectionInput,
} from "@/organization/common/sidebar/nav-section/__generated__/UpdateNavSectionButtonMutation.graphql"
import Form from "@components/form/Form"
import { displaySuccessToast } from "@components/toast/ToastProvider"
import { DiscoFormControl, DiscoInput } from "@disco-ui"
import { observer } from "mobx-react-lite"

type Props = {
  form: FormStore<UpdateNavSectionInput, UpdateNavSectionButtonMutation>
  onClose: () => void
}

function UpdateNavSectionForm({ form, onClose }: Props) {
  return (
    <Form id={"UpdateNavSectionForm"} onSubmit={handleSubmit}>
      <DiscoFormControl
        sublabel={"Section Title"}
        error={Boolean(form.errorsByField.title)}
        errorMessages={form.errorsByField.title}
        marginBottom={0}
      >
        <DiscoInput
          data-testid={"UpdateNavSectionForm.title"}
          value={form.state.title}
          onChange={(e) => (form.state.title = e.target.value)}
          placeholder={"e.g. Resources"}
        />
      </DiscoFormControl>
    </Form>
  )

  async function handleSubmit() {
    const { didSave } = await form.submit(form.state)
    if (!didSave) return

    displaySuccessToast({
      message: "Section created!",
      testid: "UpdateNavSectionForm.success-toast",
    })
    onClose()
  }
}

export default observer(UpdateNavSectionForm)
