import { DeleteNavFolderButtonFragment$key } from "@/organization/common/topbar/nav-folder/__generated__/DeleteNavFolderButtonFragment.graphql"
import { DeleteNavFolderButtonMutation } from "@/organization/common/topbar/nav-folder/__generated__/DeleteNavFolderButtonMutation.graphql"
import { displayErrorToast, displayToast } from "@components/toast/ToastProvider"
import {
  OverridableDiscoButton,
  OverridableDiscoButtonProps,
} from "@disco-ui/button/OverridableDiscoButton"
import DiscoWarningModal from "@disco-ui/modal/DiscoWarningModal"
import { useState } from "react"
import { graphql, useFragment, useMutation } from "react-relay"

type DeleteNavFolderButtonProps = OverridableDiscoButtonProps & {
  navFolderKey: DeleteNavFolderButtonFragment$key
}

export default function DeleteNavFolderButton({
  navFolderKey,
  children,
  ...props
}: DeleteNavFolderButtonProps) {
  const [isOpen, setIsOpen] = useState(false)
  const navFolder = useFragment<DeleteNavFolderButtonFragment$key>(
    graphql`
      fragment DeleteNavFolderButtonFragment on ProductApp {
        id
        customAppTitle
        productApps {
          totalCount
        }
      }
    `,
    navFolderKey
  )

  const [commit, isDeleting] = useMutation<DeleteNavFolderButtonMutation>(
    graphql`
      mutation DeleteNavFolderButtonMutation($id: ID!) {
        response: deleteNavFolder(id: $id) {
          deletedNavFolderId @deleteRecord
          product {
            id
            ...AppsSidebarList_ProductFragment
          }
          errors {
            field
            message
          }
        }
      }
    `
  )

  return (
    <>
      <OverridableDiscoButton
        {...props}
        testid={"DeleteNavSectionButton"}
        onClick={handleOpen}
      >
        {children}
      </OverridableDiscoButton>

      <DiscoWarningModal
        modalContentLabel={"Remove Folder Dialog"}
        testid={"RemoveAppButton.delete-warning-modal"}
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        title={"Remove this Folder?"}
        description={`
      Are you sure you want to delete the ${navFolder.customAppTitle} folder?
      ${
        navFolder.productApps?.totalCount
          ? "All of the apps within this folder will be moved back into the navigation bar."
          : ""
      }
    `}
        confirmationButtonProps={{
          onClick: handleSubmit,
          shouldDisplaySpinner: isDeleting,
          children: "Yes, delete it",
        }}
      />
    </>
  )

  function handleOpen() {
    setIsOpen(true)
  }

  function handleSubmit() {
    commit({
      variables: {
        id: navFolder.id,
      },
      onCompleted({ response }) {
        if (response.errors?.length) return displayErrorToast(response.errors[0].message)

        displayToast({
          message: "Folder removed!",
          testid: "DeleteNavFolderButton.success-toast",
        })
      },
      onError(error) {
        displayErrorToast(error)
      },
    })
  }
}
