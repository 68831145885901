import ROUTE_NAMES from "@/core/route/util/routeNames"
import LockIcon from "@/core/ui/iconsax/linear/lock-1.svg"
import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import PaidFeatureTooltip from "@/organization/entitlements/PaidFeatureTooltip"
import { DiscoChip, DiscoLink } from "@disco-ui"
import useHasEntitlement from "@utils/hook/useHasEntitlement"
import { Entitlement } from "@utils/hook/__generated__/useHasEntitlementActiveOrganizationFragment.graphql"
import classNames from "classnames"

interface Props {
  testid?: string
  entitlement?: Entitlement
  className?: string
  iconOnly?: boolean
}

function PaidFeatureChip(props: Props) {
  const { testid, entitlement, className, iconOnly } = props
  const classes = useStyles()

  // Hide when the given entitlement is granted.
  const hasEntitlement = useHasEntitlement(entitlement)
  if (entitlement && hasEntitlement) return null

  return (
    <PaidFeatureTooltip testid={testid} entitlement={entitlement}>
      <DiscoLink to={ROUTE_NAMES.ADMIN.SETTINGS.BILLING.ROOT}>
        <DiscoChip
          label={iconOnly ? "" : "Upgrade"}
          color={"cyan"}
          icon={<LockIcon height={16} width={16} />}
          testid={testid || "PaidFeatureChip.chip"}
          className={classNames(classes.chip, className)}
        />
      </DiscoLink>
    </PaidFeatureTooltip>
  )
}

export default PaidFeatureChip

const useStyles = makeUseStyles({
  chip: {
    cursor: "pointer",
  },
})
