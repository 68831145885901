import { useGlobalDrawer } from "@/core/context/GlobalDrawerProvider"
import UnsavedChangesModalProvider, {
  useInitUnsavedChangesModalContext,
} from "@/core/context/UnsavedChangesModalProvider"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoDrawer } from "@disco-ui"
import lazyWithRetry from "@utils/lazy/lazyWithRetry"

const EventDrawerContent = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "event-drawer-content" */ "@/organization/occurrence/EventDrawerContent"
    )
)

function EventDrawer() {
  const drawer = useGlobalDrawer("event")
  const classes = useStyles()
  const modal = useInitUnsavedChangesModalContext()

  return (
    // If leaving with unsaved changes, display the unsaved changes modal
    <UnsavedChangesModalProvider {...modal}>
      <DiscoDrawer
        open={drawer.isOpen}
        onClose={handleCloseDrawer}
        containerClasses={{ drawerContainer: classes.drawer }}
      >
        {drawer.params.drawerOccurrenceId && (
          <EventDrawerContent
            // Key ensures any/all forms inside the drawer get reinitialized when this param changes
            key={drawer.params.drawerOccurrenceId}
            occurrenceId={drawer.params.drawerOccurrenceId}
          />
        )}
      </DiscoDrawer>
    </UnsavedChangesModalProvider>
  )

  function handleCloseDrawer() {
    // Check that there are no unsaved changes before closing
    modal.handleLeave({
      onLeave: drawer.close,
    })
  }
}

const useStyles = makeUseStyles({
  drawer: {
    padding: 0,
  },
})

export default EventDrawer
