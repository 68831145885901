import { getOccurrenceStatus } from "@/occurrence/util/getOccurrenceStatus"
import { occurrenceUtils_useCanShareOccurrenceFragment$key } from "@/occurrence/util/__generated__/occurrenceUtils_useCanShareOccurrenceFragment.graphql"
import { occurrenceUtils_useOccurrenceStatusFragment$key } from "@/occurrence/util/__generated__/occurrenceUtils_useOccurrenceStatusFragment.graphql"
import useIsWaitingRoomEnabled from "@/product/util/hook/useIsWaitingRoomEnabled"
import Relay from "@/relay/relayUtils"
import useInterval from "@utils/hook/useInterval"
import usePermissions from "@utils/hook/usePermissions"
import { isFuture, isPast } from "date-fns"
import subMinutes from "date-fns/subMinutes"
import { useState } from "react"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"
import { isDateInRange } from "../../utils/time/timeUtils"
import { MINUTES_OCCURRENCE_JOIN_BUTTON_AVAILABLE_BEFORE_START_TIME } from "./occurrenceConstants"

function haveAnHourToOccurrence(
  datetimeRange: string[] | ReadonlyArray<string> | undefined | null
): boolean {
  if (!datetimeRange) return false
  return isDateInRange({
    lower: subMinutes(
      new Date(datetimeRange[0]),
      MINUTES_OCCURRENCE_JOIN_BUTTON_AVAILABLE_BEFORE_START_TIME
    ),
    upper: new Date(datetimeRange[0]),
  })
}

function isOccurrenceLive(
  datetimeRange: string[] | ReadonlyArray<string> | undefined | null
): boolean {
  if (!datetimeRange) return false
  return isDateInRange({
    lower: new Date(datetimeRange[0]),
    upper: new Date(datetimeRange[1]),
  })
}

/** get relevant data from occurrence to evaluate occurrence status using `getOccurrenceStatus` util.*/
function useOccurrenceStatus(
  occurrenceKey: occurrenceUtils_useOccurrenceStatusFragment$key
) {
  const occurrence = useFragment<occurrenceUtils_useOccurrenceStatusFragment$key>(
    graphql`
      fragment occurrenceUtils_useOccurrenceStatusFragment on Occurrence {
        id
        datetimeRange
        hasViewerRSVPd
        timezone
        product {
          viewerMembership {
            id
          }
        }
        media(first: 1) {
          edges {
            node {
              id
            }
          }
        }
        event {
          ...usePermissionsFragment
        }
      }
    `,
    occurrenceKey
  )
  const permissions = usePermissions(occurrence.event)

  const [{ isLive, isUpcoming, isEnded, isInHour }, setDatetimeState] = useState({
    isLive: isOccurrenceLive(occurrence?.datetimeRange),
    isUpcoming: isFuture(new Date(occurrence?.datetimeRange[0])),
    isEnded: isPast(new Date(occurrence?.datetimeRange[1])),
    isInHour: haveAnHourToOccurrence(occurrence?.datetimeRange),
  })

  // periodically evaluate these states
  useInterval(
    () =>
      setDatetimeState({
        isLive: isOccurrenceLive(occurrence?.datetimeRange),
        isUpcoming: isFuture(new Date(occurrence?.datetimeRange[0])),
        isEnded: isPast(new Date(occurrence?.datetimeRange[1])),
        isInHour: haveAnHourToOccurrence(occurrence?.datetimeRange),
      }),
    5000,
    {
      // Stop evaluating when occurrence is ended
      shouldStartInterval: !isEnded,
    }
  )

  return getOccurrenceStatus({
    canManageEvent: permissions.has("events.manage"),
    isLive,
    isUpcoming,
    isEnded,
    isInHour,
    member: Boolean(occurrence?.product.viewerMembership),
    attending: occurrence?.hasViewerRSVPd,
    hasMedia: Boolean(Relay.connectionToArray(occurrence?.media)[0]),
  })
}

type NoShareReason = "draft_occurrence" | "draft_product" | "at_capacity" | "waiting_room"

export function useCanShareOccurrence(
  occurrenceKey: occurrenceUtils_useCanShareOccurrenceFragment$key | null
): {
  canShare: boolean
  noShareReason?: NoShareReason
} {
  const occurrence = useFragment<occurrenceUtils_useCanShareOccurrenceFragment$key>(
    graphql`
      fragment occurrenceUtils_useCanShareOccurrenceFragment on Occurrence {
        status
        atCapacity
        product {
          status
          ...useIsWaitingRoomEnabledActiveProductFragment
        }
      }
    `,
    occurrenceKey
  )

  const { hasActiveWaitingRoom } = useIsWaitingRoomEnabled({
    productKey: occurrence?.product,
  })

  if (!occurrence) {
    return { canShare: false }
  }

  if (occurrence.status === "draft") {
    return { canShare: false, noShareReason: "draft_occurrence" }
  }
  if (occurrence.product.status === "draft") {
    return { canShare: false, noShareReason: "draft_product" }
  }
  if (occurrence.atCapacity) {
    return { canShare: false, noShareReason: "at_capacity" }
  }
  if (hasActiveWaitingRoom) {
    return { canShare: false, noShareReason: "waiting_room" }
  }
  return { canShare: true }
}

export { haveAnHourToOccurrence, isOccurrenceLive, useOccurrenceStatus }
