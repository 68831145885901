import EventsSidebarProductFilters from "@/occurrence/sidebar/EventsSidebarProductFilters"
import { EventsCalendarQueryParams } from "@/organization/occurrence/OrganizationOccurrenceList"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoDivider, DiscoText } from "@disco-ui"
import { useQueryParamState } from "@disco-ui/tabs/DiscoQueryParamTabs"
import DiscoTag from "@disco-ui/tag/DiscoTag"
import { capitalize } from "@material-ui/core"

function EventsSidebarFilters() {
  const classes = useStyles()
  const [params, setParams] = useQueryParamState<EventsCalendarQueryParams>()

  if (!params.dateFilter && !params.productIds) return null

  return (
    <>
      <DiscoDivider />
      <DiscoText
        variant={"body-sm-500"}
        marginTop={2}
        paddingLeft={2}
        color={"groovy.neutral.500"}
      >
        {"Filter by:"}
      </DiscoText>
      <div className={classes.filters}>
        {params.dateFilter && (
          <DiscoTag
            testid={`EventsSidebar.active-filter.${params.dateFilter}`}
            name={capitalize(params.dateFilter)}
            onDelete={removeDateFilter}
          />
        )}
        {params.productIds && <EventsSidebarProductFilters />}
      </div>
    </>
  )

  function removeDateFilter() {
    setParams({ dateFilter: undefined })
  }
}

const useStyles = makeUseStyles((theme) => ({
  filters: {
    display: "flex",
    flexWrap: "wrap",
    gap: theme.spacing(1),
    padding: theme.spacing(1, 2),
  },
}))

export default EventsSidebarFilters
