import FormStore from "@/core/form/store/FormStore"
import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import { CreateEventFeedbackInput } from "@/occurrence/feedback/form/__generated__/EventFeedbackFormMutation.graphql"
import {
  DiscoCheckbox,
  DiscoFormControl,
  DiscoIcon,
  DiscoInput,
  DiscoInputSkeleton,
  DiscoRating,
  DiscoText,
  DiscoTextSkeleton,
} from "@disco-ui"
import { useTheme } from "@material-ui/core"
import { concatenateTestId } from "@utils/string/stringUtils"
import { observer } from "mobx-react-lite"

interface EventFeedbackFormFieldsProps {
  form: FormStore<CreateEventFeedbackInput>
  testid: string
}

const EventFeedbackFormFields = observer<EventFeedbackFormFieldsProps>(
  ({ form, testid }) => {
    const classes = useStyles()
    const TESTID = concatenateTestId(testid, "EventFeedbackFormFields")
    const theme = useTheme()
    const starIcon = (
      <DiscoIcon
        icon={"star"}
        active={true}
        color={theme.palette.groovy.grey[100]}
        className={classes.starIcon}
      />
    )

    return (
      <>
        <DiscoFormControl
          errorMessages={form.errorsByField.instructorRating}
          marginBottom={3}
        >
          <div className={classes.ratingRow}>
            <DiscoText variant={"body-lg-600"}>{"Instructor(s)"}</DiscoText>
            <DiscoRating
              data-testid={`${TESTID}.instructor-rating`}
              value={form.state.instructorRating}
              onChange={(_, v) => (form.state.instructorRating = v || 0)}
              icon={starIcon}
            />
          </div>
        </DiscoFormControl>

        <DiscoFormControl
          errorMessages={form.errorsByField.videoQualityRating}
          marginBottom={3}
        >
          <div className={classes.ratingRow}>
            <DiscoText variant={"body-lg-600"}>{"Video Quality"}</DiscoText>
            <DiscoRating
              data-testid={`${TESTID}.video-quality-rating`}
              value={form.state.videoQualityRating}
              onChange={(_, v) => (form.state.videoQualityRating = v || 0)}
              icon={starIcon}
            />
          </div>
        </DiscoFormControl>

        <DiscoFormControl
          errorMessages={form.errorsByField.contentRating}
          marginBottom={3}
        >
          <div className={classes.ratingRow}>
            <DiscoText variant={"body-lg-600"}>{"Content"}</DiscoText>
            <DiscoRating
              data-testid={`${TESTID}.content-rating`}
              value={form.state.contentRating}
              onChange={(_, v) => {
                form.state.contentRating = v || 0
              }}
              icon={starIcon}
            />
          </div>
        </DiscoFormControl>
        <DiscoFormControl errorMessages={form.errorsByField.comments} marginBottom={1.5}>
          <DiscoText variant={"body-sm"} marginBottom={0.5} color={"text.secondary"}>
            {"Any specific comments you'd like to share?"}
          </DiscoText>
          <DiscoInput
            inputProps={{
              "data-testid": `${TESTID}.comments`,
            }}
            title={"comments"}
            multiline
            error={Boolean(form.errorsByField.comments)}
            placeholder={
              "What you enjoyed most, opportunities for improvement, your top takeaway, something you're confused about, etc."
            }
            onChange={(e) => (form.state.comments = e.target.value)}
            style={{ alignItems: "start" }}
            rows={3}
          />
        </DiscoFormControl>
        <DiscoFormControl marginBottom={0}>
          <DiscoCheckbox
            name={"provideFeedbackAnonymously"}
            checked={form.state.anonymous!}
            onChange={() => (form.state.anonymous = !form.state.anonymous)}
            label={
              <DiscoText variant={"body-sm"} color={"text.secondary"}>
                {"Provide feedback anonymously."}
              </DiscoText>
            }
            data-testid={"EventFeedbackForm.provide-feedback-anonymously"}
          />
        </DiscoFormControl>
      </>
    )
  }
)

const useStyles = makeUseStyles((theme) => ({
  starIcon: {
    height: "40px",
    width: "47px",
    [theme.breakpoints.down("xs")]: {
      height: "30px",
      width: "35px",
    },
  },
  ratingRow: {
    display: "flex",
    alignItems: "center",
    "& > *": {
      flexBasis: "24%",
      [theme.breakpoints.down("xs")]: {
        flexBasis: "28%",
      },
    },
    "& > :first-child": {
      minWidth: "100px",
      [theme.breakpoints.down("xs")]: {
        marginRight: theme.spacing(1),
      },
    },
  },
}))

export const EventFeedbackFormFieldsSkeleton = () => {
  return (
    <>
      <DiscoFormControl>
        <DiscoTextSkeleton variant={"heading-md"} width={250} />
      </DiscoFormControl>
      <DiscoFormControl>
        <DiscoTextSkeleton variant={"heading-md"} width={250} />
      </DiscoFormControl>
      <DiscoFormControl>
        <DiscoTextSkeleton variant={"heading-md"} width={250} />
      </DiscoFormControl>
      <DiscoFormControl>
        <DiscoTextSkeleton variant={"heading-md"} width={100} marginBottom={2} />
        <DiscoInputSkeleton height={150} />
      </DiscoFormControl>
    </>
  )
}

export default EventFeedbackFormFields
