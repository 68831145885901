import EventsProductsFilterDropdown from "@/occurrence/sidebar/EventsProductsFilterDropdown"
import EventsStatusFilterDropdown from "@/occurrence/sidebar/EventsStatusFilterDropdown"
import { DiscoDropdown, DiscoIcon, DiscoIconButton, DiscoText } from "@disco-ui"
import { useTheme } from "@material-ui/core"
import { useCallback } from "react"

function EventsSidebarFilterDropdown() {
  const theme = useTheme()

  const menuButton = useCallback(
    ({ onClick }) => (
      <DiscoIconButton
        testid={"EventsSidebar.filter-button"}
        size={"small"}
        width={32}
        height={32}
        svgStyles={{ height: 20, width: 20 }}
        onClick={onClick}
        background={theme.palette.groovy.neutral[100]}
        color={theme.palette.groovy.neutral[500]}
      >
        <DiscoIcon icon={"filter-bars"} />
      </DiscoIconButton>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  return (
    <DiscoDropdown
      menuButton={menuButton}
      testid={"EventsSidebar.filter-dropdown"}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
    >
      <DiscoText
        variant={"body-xs-500"}
        marginTop={0.5}
        marginBottom={0.5}
        color={"groovy.grey.400"}
      >
        {"Filter by..."}
      </DiscoText>
      <EventsStatusFilterDropdown />
      <EventsProductsFilterDropdown />
    </DiscoDropdown>
  )
}

export default EventsSidebarFilterDropdown
