import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { useFormStore } from "@/core/form/store/FormStore"
import CreateNavSectionForm from "@/organization/common/sidebar/nav-section/CreateNavSectionForm"
import {
  CreateNavSectionButtonMutation,
  NavSectionKind,
} from "@/organization/common/sidebar/nav-section/__generated__/CreateNavSectionButtonMutation.graphql"
import Form from "@components/form/Form"
import { DiscoModal } from "@disco-ui"
import {
  OverridableDiscoButton,
  OverridableDiscoButtonProps,
} from "@disco-ui/button/OverridableDiscoButton"
import { useEffect, useState } from "react"
import { graphql } from "relay-runtime"

type Props = OverridableDiscoButtonProps & {
  navSectionIndex: number
  kind?: NavSectionKind
}

function CreateNavSectionButton({ children, navSectionIndex, kind, ...props }: Props) {
  const activeOrganization = useActiveOrganization()!
  const [isOpen, setIsOpen] = useState(false)

  const form = useFormStore<CreateNavSectionButtonMutation>(
    graphql`
      mutation CreateNavSectionButtonMutation($input: CreateNavSectionInput!) {
        response: createNavSection(input: $input) {
          node {
            id
            ordering
            ...NavSectionFragment
          }
          errors {
            field
            message
          }
        }
      }
    `,
    {
      organizationId: activeOrganization.id,
      ordering: navSectionIndex,
      title: "",
      kind,
    }
  )

  useEffect(() => {
    form.setInitialState({ ordering: navSectionIndex })
  }, [form, navSectionIndex])

  return (
    <>
      <OverridableDiscoButton
        {...props}
        testid={`CreateNavSectionButton.${navSectionIndex}`}
        onClick={() => setIsOpen(true)}
      >
        {children}
      </OverridableDiscoButton>

      <DiscoModal
        isOpen={isOpen}
        onClose={handleClose}
        testid={"CreateNavSectionModal"}
        modalContentLabel={"New Section Setup"}
        title={"New Section Setup"}
        subtitle={"Sections are used to group relevant navigation items."}
        width={"100%"}
        maxWidth={"540px"}
        minHeight={"unset"}
        body={<CreateNavSectionForm form={form} onClose={handleClose} />}
        buttons={
          <Form.SubmitButton
            id={"CreateNavSectionForm"}
            testid={"CreateNavSectionForm.submit"}
            form={form}
          >
            {"Complete Setup"}
          </Form.SubmitButton>
        }
      />
    </>
  )

  function handleClose() {
    form.state.title = ""
    form.resetInitialState()
    setIsOpen(false)
  }
}

export default CreateNavSectionButton
