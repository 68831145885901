/* eslint-disable complexity */
import CollectionAppSettingsButton from "@/apps/list/app/collections/CollectionAppSettingsButton"
import GenericAppSettingsButton from "@/apps/list/app/common/settings/GenericAppSettingsButton"
import ContentAppSettingsButton from "@/apps/list/app/content/settings-button/ContentAppSettingsButton"
import EventsAppSettingsButton from "@/apps/list/app/events/EventsAppSettingsButton"
import FeedAppSettingsButton from "@/apps/list/app/feed/settings-button/FeedAppSettingsButton"
import LinkAppSettingsButton from "@/apps/list/app/link/settings-button/LinkAppSettingsButton"
import MembersAppSettingsButton from "@/apps/list/app/members/button/MembersAppSettingsButton"
import NavFolderAddAppButton from "@/apps/nav-folder/NavFolderAddAppButton"
import AppSidebarItem, { AppSidebarItemProps } from "@/apps/sidebar-item/AppSidebarItem"
import SlackProductAppSidebarItem from "@/apps/sidebar-item/SlackProductAppSidebarItem"
import { ActiveAppModalProvider } from "@/apps/util/activeAppModalContext"
import { AppLevelProvider } from "@/apps/util/appLevelContext"
import { useContentUsageDrawer } from "@/content-usage/drawer/useContentUsageDrawer"
import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import ROUTE_NAMES from "@/core/route/util/routeNames"
import { useRenderProductAppItemFragment$data } from "@/organization/common/sidebar/my-experiences-list/util/__generated__/useRenderProductAppItemFragment.graphql"
import generateCoursePaths from "@/organization/common/sidebar/my-experiences-list/util/generateCoursePaths"
import NavFolderSettingsDropdown from "@/organization/common/topbar/nav-folder/NavFolderSettingsDropdown"
import ChatChannelSideBarItem from "@/product/sidebar/chat/channels/ChatChannelSideBarItem"
import Relay from "@/relay/relayUtils"
import { DiscoIcon } from "@disco-ui"
import DiscoDropdownItem from "@disco-ui/dropdown/DiscoDropdownItem"
import React, { Fragment } from "react"
import { generatePath } from "react-router"
import { graphql } from "relay-runtime"

interface Props {
  product?: {
    id: string
    slug: string
  } | null
  testid: string
}

export type AppData = Omit<useRenderProductAppItemFragment$data, " $fragmentType">

type RenderProductAppItemArgs = Pick<
  AppSidebarItemProps,
  "children" | "isDragging" | "dragHandleProps"
> & {
  app: AppData
}

function useRenderProductAppItem({ testid, product }: Props) {
  const COURSE_PATHS = product ? generateCoursePaths(product.slug) : null
  const activeOrganization = useActiveOrganization()!
  const contentUsageDrawer = useContentUsageDrawer()

  /* eslint-disable complexity */
  return function renderProductAppItem({
    app: productApp,
    isDragging,
    dragHandleProps,
    children,
  }: RenderProductAppItemArgs): React.ReactElement {
    const defaultProps: AppSidebarItemProps & { key: string } = {
      key: productApp.id,
      appKey: productApp,
      isDragging,
      dragHandleProps,
    }

    switch (productApp.kind) {
      case "nav_folder":
        return (
          <AppLevelProvider productKey={productApp.product!} navFolderId={productApp.id}>
            <ActiveAppModalProvider>
              <AppSidebarItem
                {...defaultProps}
                testid={`${testid}.${productApp.customAppTitle}`}
                notificationConfig={{
                  filter: {
                    kinds: [
                      "assignment-submitted",
                      "course-content-publish",
                      "quiz-submitted",
                      "survey-submitted",
                    ],
                    folderId: productApp.id,
                  },
                  streamChannelIds: Relay.connectionToArray(productApp.productApps)
                    .map((a) => a.chatChannel?.externalChannelId)
                    .filter((v): v is NonNullable<typeof v> => Boolean(v)),
                }}
                name={productApp.customAppTitle || ""}
                settingsButton={<NavFolderSettingsDropdown appKey={productApp} />}
                collapsible
                indentNestedItems
                hideLeftIcon
                showOnHoverRightContent={<NavFolderAddAppButton folder={productApp} />}
              >
                {children}
              </AppSidebarItem>
            </ActiveAppModalProvider>
          </AppLevelProvider>
        )
      case "posts": {
        if (!productApp.feed || !productApp.feed.viewerPermissions.includes("posts.read"))
          return <Fragment key={productApp.id} />
        return (
          <AppSidebarItem
            {...defaultProps}
            testid={`${testid}.${productApp.feed.name}`}
            to={
              productApp.product
                ? generatePath(ROUTE_NAMES.PRODUCT.FEED.POSTS.LIST, {
                    productSlug: productApp.product.slug,
                    feedId: productApp.feed.id,
                  })
                : generatePath(ROUTE_NAMES.COMMUNITY.FEED.POSTS.LIST, {
                    feedId: productApp.feed.id,
                  })
            }
            notificationConfig={{
              filter: {
                kind: "course-content-publish",
                feedId: productApp.feed.id,
              },
            }}
            name={productApp.feed.name}
            settingsButton={
              <FeedAppSettingsButton appKey={productApp}>
                {(buttonProps) => (
                  <DiscoDropdownItem
                    {...buttonProps}
                    title={"Edit"}
                    testid={`${testid}.${productApp.feed?.name}.overflow.edit`}
                  />
                )}
              </FeedAppSettingsButton>
            }
          />
        )
      }
      case "curriculum": {
        return (
          <AppSidebarItem
            {...defaultProps}
            testid={`${testid}.Curriculum`}
            to={COURSE_PATHS?.CURRICULUM.ROOT}
            notificationConfig={{
              filter: {
                kinds: ["assignment-submitted", "quiz-submitted", "survey-submitted"],
                productId: product?.id,
              },
            }}
            settingsButton={
              <GenericAppSettingsButton appKey={productApp}>
                {(buttonProps) => (
                  <DiscoDropdownItem
                    {...buttonProps}
                    key={`${productApp.id}.more-actions.edit`}
                    title={"Edit"}
                    testid={`${testid}.${productApp.customAppTitle}.more-actions.edit`}
                  />
                )}
              </GenericAppSettingsButton>
            }
          />
        )
      }
      case "events":
        return (
          <AppSidebarItem
            {...defaultProps}
            testid={`${testid}.Events`}
            to={COURSE_PATHS?.EVENTS}
            settingsButton={
              <GenericAppSettingsButton appKey={productApp}>
                {(buttonProps) => (
                  <DiscoDropdownItem
                    {...buttonProps}
                    key={`${productApp.id}.more-actions.edit`}
                    title={"Edit"}
                    testid={`${testid}.${productApp.customAppTitle}.more-actions.edit`}
                  />
                )}
              </GenericAppSettingsButton>
            }
          />
        )
      case "members": {
        return (
          <AppSidebarItem
            {...defaultProps}
            testid={`${testid}.Members`}
            to={COURSE_PATHS?.MEMBERS.LIST}
          />
        )
      }
      case "resources":
        return (
          <AppSidebarItem
            {...defaultProps}
            testid={`${testid}.Resources`}
            to={COURSE_PATHS?.RESOURCES}
          />
        )
      case "slack":
        return (
          <SlackProductAppSidebarItem
            key={productApp.id}
            testid={`${testid}.Slack`}
            appKey={productApp}
            isDragging={isDragging}
          />
        )
      case "content": {
        if (!productApp.contentUsage) return <Fragment key={productApp.id} />
        return (
          <AppSidebarItem
            {...defaultProps}
            testid={`${testid}.Content-${productApp.customAppTitle}`}
            onClick={() => {
              contentUsageDrawer.open({
                drawerContentUsageId: productApp.contentUsage!.id,
              })
            }}
            settingsButton={
              <ContentAppSettingsButton appKey={productApp}>
                {(buttonProps) => <DiscoDropdownItem {...buttonProps} title={"Edit"} />}
              </ContentAppSettingsButton>
            }
          />
        )
      }
      case "link": {
        return (
          <AppSidebarItem
            {...defaultProps}
            testid={`${testid}.ExternalLink-${productApp.customAppTitle}`}
            externalLink={productApp.customAppUrl!}
            settingsButton={
              <LinkAppSettingsButton appKey={productApp}>
                {(buttonProps) => <DiscoDropdownItem {...buttonProps} title={"Edit"} />}
              </LinkAppSettingsButton>
            }
          />
        )
      }
      case "chat_channel":
        if (!activeOrganization.isChannelsEnabled) return <Fragment key={productApp.id} />
        return (
          <ChatChannelSideBarItem
            {...defaultProps}
            key={productApp.id}
            testid={`${testid}.ChatChannel`}
            appKey={productApp}
          />
        )
      case "collection":
        if (!productApp.collection) return <Fragment key={productApp.id} />
        return (
          <AppSidebarItem
            {...defaultProps}
            testid={`${testid}.Collection-${productApp.customAppTitle}`}
            settingsButton={
              <CollectionAppSettingsButton appKey={productApp}>
                {(buttonProps) => (
                  <DiscoDropdownItem
                    {...buttonProps}
                    key={`${productApp.id}.more-actions.edit`}
                    title={"Edit"}
                    testid={`${testid}.${productApp.customAppTitle}.more-actions.edit`}
                  />
                )}
              </CollectionAppSettingsButton>
            }
            to={
              productApp.product
                ? generatePath(ROUTE_NAMES.PRODUCT.COLLECTION.DETAIL, {
                    productSlug: productApp.product.slug,
                    collectionId: productApp.collection!.id,
                  })
                : generatePath(ROUTE_NAMES.COMMUNITY.COLLECTION.DETAIL, {
                    collectionId: productApp.collection!.id,
                  })
            }
          />
        )
      case "organization_events":
        return (
          <AppSidebarItem
            {...defaultProps}
            testid={`${testid}.Events-${productApp.customAppTitle}`}
            to={generatePath(ROUTE_NAMES.COMMUNITY.EVENTS.APP, { appId: productApp.id })}
            appKey={productApp}
            isDragging={isDragging}
            dragHandleProps={dragHandleProps}
            settingsButton={
              <EventsAppSettingsButton appKey={productApp}>
                {(buttonProps) => (
                  <DiscoDropdownItem
                    {...buttonProps}
                    key={`${productApp.id}.more-actions.edit`}
                    title={"Edit"}
                    testid={`${testid}.${productApp.customAppTitle}.more-actions.edit`}
                  />
                )}
              </EventsAppSettingsButton>
            }
          />
        )
      case "organization_members":
        return (
          <AppSidebarItem
            key={productApp.id}
            testid={`${testid}.Members`}
            to={ROUTE_NAMES.COMMUNITY.MEMBERS.LIST}
            appKey={productApp}
            isDragging={isDragging}
            dragHandleProps={dragHandleProps}
            settingsButton={
              <MembersAppSettingsButton appKey={productApp}>
                {(buttonProps) => (
                  <DiscoDropdownItem
                    {...buttonProps}
                    key={`${productApp.id}.more-actions.edit`}
                    title={"Edit App"}
                    testid={`${testid}.${productApp.customAppTitle}.more-actions.edit`}
                    icon={<DiscoIcon icon={"pencil"} />}
                  />
                )}
              </MembersAppSettingsButton>
            }
          />
        )
      case "product_link":
        return (
          <AppSidebarItem
            {...defaultProps}
            testid={`${testid}.ProductLink.${productApp.customAppTitle}`}
            to={generatePath(ROUTE_NAMES.PRODUCT.DASHBOARD, {
              productSlug: productApp.linkProduct!.slug,
            })}
            settingsButton={
              <LinkAppSettingsButton appKey={productApp}>
                {(buttonProps) => <DiscoDropdownItem {...buttonProps} title={"Edit"} />}
              </LinkAppSettingsButton>
            }
          />
        )
      default:
        return <Fragment key={productApp.id} />
    }
  }
}

export default useRenderProductAppItem

// eslint-disable-next-line no-unused-expressions
graphql`
  fragment useRenderProductAppItemFragment on ProductApp {
    id
    kind
    customAppUrl
    customAppTitle
    feed {
      id
      name
      viewerPermissions
      ...usePermissionsFragment
    }
    product {
      slug
      name
      ...usePermissionsFragment
      ...appLevelContextFragment
    }
    collection {
      id
    }
    contentUsage {
      id
    }
    linkProduct {
      slug
    }
    productApps {
      edges {
        node {
          id
          chatChannel {
            externalChannelId
          }
        }
      }
    }
    ...AppSidebarItemFragment
    ...ContentAppSettingsButtonFragment
    ...LinkAppSettingsButtonFragment
    ...FeedAppSettingsButtonFragment
    ...GenericAppSettingsButtonFragment
    ...CollectionAppSettingsButtonFragment
    ...ChatChannelSettingsModalButtonFragment
    ...ChatChannelSideBarItemFragment
    ...NavFolderSettingsDropdownFragment
    ...EventsAppSettingsButtonFragment
    ...MembersAppSettingsButtonFragment
  }
`
