import { OccurrenceWithDetailsFragment$key } from "@/occurrence/common/__generated__/OccurrenceWithDetailsFragment.graphql"
import useUserTimezone from "@/user/util/useUserTimezone"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import DaySquare from "@components/date/day-square/DaySquare"
import { DiscoText } from "@disco-ui"
import { DATE_FORMAT } from "@utils/time/timeConstants"
import { formatDateWithOptions } from "@utils/time/timeUtils"
import { TestIDProps } from "@utils/typeUtils"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"

interface Props extends TestIDProps {
  occurrenceKey: OccurrenceWithDetailsFragment$key
  hideDates?: boolean
}

function OccurrenceWithDetails(props: Props) {
  const { occurrenceKey, hideDates, testid = "OccurrenceWithDetails" } = props

  const classes = useStyles()
  const timezone = useUserTimezone()

  const occurrence = useFragment<OccurrenceWithDetailsFragment$key>(
    graphql`
      fragment OccurrenceWithDetailsFragment on Occurrence {
        id
        name
        datetimeRange
      }
    `,
    occurrenceKey
  )

  const startsAt = new Date(occurrence.datetimeRange[0])

  return (
    <div className={classes.root}>
      <div className={classes.iconWrapper}>
        <DaySquare
          testid={`${testid}.DaySquare`}
          customClassName={classes.daySquare}
          date={startsAt}
        />
      </div>
      <div>
        <DiscoText variant={"body-sm-700"} testid={`${testid}.name`}>
          {occurrence.name}
        </DiscoText>
        {!hideDates && (
          <DiscoText
            variant={"body-xs-500"}
            color={"text.secondary"}
            testid={`${testid}.date`}
          >
            {formatDateWithOptions({
              timeZone: timezone,
              format: DATE_FORMAT.DEFAULT_DATE_WITH_SHORT_TIME_FORMAT_WITH_OFFSET_AND_AT,
            })(startsAt)}
          </DiscoText>
        )}
      </div>
    </div>
  )
}

const useStyles = makeUseStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
  },
  iconWrapper: {
    width: "48px",
    height: "48px",
    marginRight: theme.spacing(1.5),
    [theme.breakpoints.down("sm")]: {
      width: "40px",
      height: "40px",
    },
  },
  daySquare: {
    [theme.breakpoints.down("sm")]: {
      width: "40px",
      height: "40px",
    },
  },
}))

export default OccurrenceWithDetails
