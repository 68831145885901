import { useFormStore } from "@/core/form/store/FormStore"
import UpdateNavSectionForm from "@/organization/common/sidebar/nav-section/UpdateNavSectionForm"
import { UpdateNavSectionButtonFragment$key } from "@/organization/common/sidebar/nav-section/__generated__/UpdateNavSectionButtonFragment.graphql"
import { UpdateNavSectionButtonMutation } from "@/organization/common/sidebar/nav-section/__generated__/UpdateNavSectionButtonMutation.graphql"
import Form from "@components/form/Form"
import { DiscoModal } from "@disco-ui"
import {
  OverridableDiscoButton,
  OverridableDiscoButtonProps,
} from "@disco-ui/button/OverridableDiscoButton"
import { useState } from "react"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"

type Props = OverridableDiscoButtonProps & {
  navSectionKey: UpdateNavSectionButtonFragment$key
}

function UpdateNavSectionButton({ children, navSectionKey, ...props }: Props) {
  const [isOpen, setIsOpen] = useState(false)

  const navSection = useFragment<UpdateNavSectionButtonFragment$key>(
    graphql`
      fragment UpdateNavSectionButtonFragment on NavSection {
        id
        title
      }
    `,
    navSectionKey
  )

  const form = useFormStore<UpdateNavSectionButtonMutation>(
    graphql`
      mutation UpdateNavSectionButtonMutation($input: UpdateNavSectionInput!) {
        response: updateNavSection(input: $input) {
          node {
            id
            title
          }
          errors {
            field
            message
          }
        }
      }
    `,
    {
      id: navSection.id,
      title: navSection.title,
    }
  )

  return (
    <>
      <OverridableDiscoButton
        {...props}
        testid={"UpdateNavSectionButton"}
        onClick={() => setIsOpen(true)}
      >
        {children}
      </OverridableDiscoButton>

      <DiscoModal
        isOpen={isOpen}
        onClose={handleClose}
        testid={"UpdateNavSectionModal"}
        modalContentLabel={"Edit Section"}
        title={"Edit Section"}
        width={"100%"}
        maxWidth={"540px"}
        minHeight={"unset"}
        body={<UpdateNavSectionForm form={form} onClose={handleClose} />}
        buttons={
          <Form.SubmitButton
            id={"UpdateNavSectionForm"}
            testid={"UpdateNavSectionForm.submit"}
            form={form}
          >
            {"Save Changes"}
          </Form.SubmitButton>
        }
      />
    </>
  )

  function handleClose() {
    form.reset()
    setIsOpen(false)
  }
}

export default UpdateNavSectionButton
