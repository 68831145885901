import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import styleIf from "@assets/style/util/styleIf"
import { DiscoIcon, DiscoText } from "@disco-ui"

interface Props {
  width?: string
  iconOnly?: boolean
}

function OccurrenceAttendingLabel({ width, iconOnly = false }: Props) {
  const classes = useStyles({ width, iconOnly })
  return (
    <div className={classes.label} data-testid={"OccurrenceAttendingLabel"}>
      <DiscoIcon
        icon={"iconsax.custom-check"}
        className={classes.text}
        width={24}
        height={24}
      />
      {!iconOnly && (
        <DiscoText variant={"body-sm-600"} className={classes.text}>
          {"Attending"}
        </DiscoText>
      )}
    </div>
  )
}

interface StyleProps {
  width?: string
  iconOnly?: boolean
}

const useStyles = makeUseStyles((theme) => ({
  label: ({ width, iconOnly }: StyleProps) => ({
    backgroundColor: theme.palette.success.light,
    borderRadius: theme.measure.borderRadius.medium,
    display: "flex",
    gap: theme.spacing(1),
    padding: theme.spacing(1, 1.5),
    justifyContent: "center",
    ...styleIf(width, { width }),
    ...styleIf(iconOnly, {
      padding: theme.spacing(1.5),
    }),
  }),
  text: {
    color: theme.palette.success.main,
  },
}))

export default OccurrenceAttendingLabel
