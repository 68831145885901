type OccurrenceStatusType =
  | "register"
  | "register-while-live"
  | "rsvp"
  | "rsvp-while-live"
  | "attending"
  | "live-in-hour"
  | "live"
  | "ended-upload-recording"
  | "ended-watch-recording"
  | "ended"
  | "unknown"

type OccurrenceStatus = {
  canManageEvent: boolean
  member: boolean
  attending: boolean
  hasMedia: boolean
  isLive: boolean
  isUpcoming: boolean
  isEnded: boolean
  isInHour: boolean
}

function getOccurrenceStatus({
  canManageEvent,
  member,
  attending,
  hasMedia,
  isLive,
  isUpcoming,
  isEnded,
  isInHour,
}: OccurrenceStatus): OccurrenceStatusType {
  // A user should be able to register for an event up until its end
  // A user should be aware if an event is live, even if they are not registered
  if (isLive && !member && !attending) return "register-while-live"
  if (!isEnded && !member && !attending) return "register"

  // A learner or admin should be able to rsvp to an upcoming experience event
  const canRSVP = member && !attending
  // TO DO: would be better to allow users to join without forcing RSVP when live, but we need to handle attendance in that case
  if (isLive && canRSVP) return "rsvp-while-live"
  if (isUpcoming && canRSVP) return "rsvp"

  // Members should see if the event is live
  if (isLive) return "live"

  // Members should see if the event is live in one hour
  if (isInHour) return "live-in-hour"

  // Admins, and members who have rsvp'd are attending (should see attending and/or countdown)
  if (isUpcoming) return "attending"

  // If the event is ended, admins should have the option to upload media
  if (isEnded && !hasMedia && canManageEvent) return "ended-upload-recording"

  // If the event is ended and there is a recording, the recording should be visible
  if (isEnded && hasMedia) return "ended-watch-recording"

  // Any users should be aware the event is ended
  if (isEnded) return "ended"

  return "unknown"
}

export { OccurrenceStatus, OccurrenceStatusType, getOccurrenceStatus }
