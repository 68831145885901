import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import { MemberGroupEventsListItemFragment$key } from "@/occurrence/calendar/modal/list/item/__generated__/MemberGroupEventsListItemFragment.graphql"
import OccurrenceAttendingButton from "@/organization/occurrence/OccurrenceAttendingButton"
import OccurrenceAttendingLabel from "@/organization/occurrence/OccurrenceAttendingLabel"
import Relay from "@/relay/relayUtils"
import RSVPToEventButton from "@components/buttons/RSVPToEventButton"
import DaySquare from "@components/date/day-square/DaySquare"
import { DiscoButtonSkeleton, DiscoText, DiscoTextSkeleton } from "@disco-ui"
import { Theme, useMediaQuery } from "@material-ui/core"
import { Skeleton } from "@material-ui/lab"
import { DATE_FORMAT } from "@utils/time/timeConstants"
import { formatDateWithOptions } from "@utils/time/timeUtils"
import { TestIDProps } from "@utils/typeUtils"
import React from "react"
import { graphql, useFragment } from "react-relay"

interface MemberGroupEventsListItemProps extends TestIDProps {
  occurrenceKey: MemberGroupEventsListItemFragment$key
}

const MemberGroupEventsListItem: React.FC<MemberGroupEventsListItemProps> = ({
  testid = "MemberGroupEventsListItem",
  occurrenceKey,
}: MemberGroupEventsListItemProps) => {
  const classes = useStyles()
  const isXsScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down("xs"))

  const occurrence = useFragment<MemberGroupEventsListItemFragment$key>(
    graphql`
      fragment MemberGroupEventsListItemFragment on Occurrence {
        id
        name
        datetimeRange
        hasViewerRSVPd
        ...RSVPToEventButtonFragment
        ...OccurrenceAttendingButtonFragment
      }
    `,
    occurrenceKey
  )

  return (
    <li className={classes.container}>
      {/* Date Card */}
      <DaySquare
        testid={"MemberGroupEventsListItem.day-square"}
        customClassName={classes.daySquare}
        customSquareMonthClassName={classes.squareMonthClassName}
        customSquareDayClassName={classes.squareDayClassName}
        date={new Date(occurrence.datetimeRange[0])}
      />

      <div>
        {/* Title */}
        <DiscoText variant={"body-lg-600"}>{occurrence.name}</DiscoText>

        {/* Date */}
        <DiscoText>
          {formatDateWithOptions({
            format: DATE_FORMAT.DEFAULT_DATE_WITH_SHORT_TIME_FORMAT_AT,
            shouldShiftDateToCompensateForTimezone: false,
          })(new Date(occurrence.datetimeRange[0]))}
        </DiscoText>
      </div>

      {/* Send ICS file or display OccurrenceAttendingLabel if the user is already attending */}
      {occurrence.hasViewerRSVPd ? (
        isXsScreen ? (
          <OccurrenceAttendingLabel iconOnly />
        ) : (
          <div>
            <OccurrenceAttendingButton occurrenceKey={occurrence} testid={testid} />
          </div>
        )
      ) : (
        <RSVPToEventButton
          occurrenceKey={occurrence}
          testid={testid}
          iconOnly={isXsScreen}
        />
      )}
    </li>
  )
}

const useStyles = makeUseStyles((theme) => ({
  container: {
    display: "grid",
    gridTemplateColumns: "max-content 1fr max-content",
    padding: theme.spacing(3, 0),
    alignItems: "center",
    gap: theme.spacing(2),
  },
  daySquare: {
    width: "64px",
    height: "64px",

    [theme.breakpoints.down("md")]: {
      width: "45px",
      height: "45px",
    },
  },
  squareMonthClassName: {
    fontSize: "14px",

    [theme.breakpoints.down("sm")]: {
      fontSize: "10px",
    },
  },
  squareDayClassName: {
    fontSize: "18px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "13px",
    },
  },
}))

export const MemberGroupEventsListItemSkeleton: React.FC = () => {
  const classes = useStyles()

  return (
    <li className={classes.container}>
      <Skeleton width={45} height={45} />
      <div>
        <DiscoTextSkeleton width={"200px"} />
        <DiscoTextSkeleton width={"300px"} />
      </div>
      <DiscoButtonSkeleton width={"185px"} />
    </li>
  )
}

export default Relay.withSkeleton<MemberGroupEventsListItemProps>({
  component: MemberGroupEventsListItem,
  skeleton: MemberGroupEventsListItemSkeleton,
})
