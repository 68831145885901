import CreateExperienceButton from "@/admin/experiences/create/CreateExperienceButton"
import AddAppButton from "@/apps/add-app-button/AddAppButton"
import AppsSidebarList from "@/apps/sidebar-item/AppsSidebarList"
import { ActiveAppModalProvider } from "@/apps/util/activeAppModalContext"
import { AppLevelProvider } from "@/apps/util/appLevelContext"
import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { useLabel } from "@/core/context/LabelsContext"
import { useStreamChat } from "@/core/context/StreamChatContext"
import { useProductSlug } from "@/core/route/util/routeUtils"
import CommunitySidebarItem from "@/organization/common/sidebar/CommunitySidebarItem"
import CreateNavSectionButton from "@/organization/common/sidebar/nav-section/CreateNavSectionButton"
import DeleteNavSectionButton from "@/organization/common/sidebar/nav-section/DeleteNavSectionButton"
import UpdateNavSectionButton from "@/organization/common/sidebar/nav-section/UpdateNavSectionButton"
import { NavSectionFragment$key } from "@/organization/common/sidebar/nav-section/__generated__/NavSectionFragment.graphql"
import ProductsSidebarList from "@/product/sidebar/ProductsSidebarList"
import useIsAdminViewingAsMember from "@/product/util/hook/useIsAdminViewingAsMember"
import Relay from "@/relay/relayUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import styleIf from "@assets/style/util/styleIf"
import {
  DiscoDivider,
  DiscoIcon,
  DiscoIconButton,
  DiscoSideBarItemSkeleton,
  DiscoText,
  DiscoTooltip,
} from "@disco-ui"
import DiscoDividerButton from "@disco-ui/button/DiscoDividerButton"
import DiscoDropdownItem from "@disco-ui/dropdown/DiscoDropdownItem"
import { useTheme } from "@material-ui/core"
import { useEffect, useState } from "react"
import { DraggableProvidedDragHandleProps } from "react-beautiful-dnd"
import { graphql, useFragment } from "react-relay"

type Props = {
  navSectionKey: NavSectionFragment$key
  index: number
  isDragging: boolean
  dragHandleProps?: DraggableProvidedDragHandleProps
  defaultCollapsed?: boolean
}

function NavSection(props: Props) {
  const {
    navSectionKey,
    index,
    isDragging,
    dragHandleProps,
    defaultCollapsed = false,
  } = props
  const activeOrganization = useActiveOrganization()!
  const theme = useTheme()
  const productLabel = useLabel("admin_experience")

  const navSection = useFragment<NavSectionFragment$key>(
    graphql`
      fragment NavSectionFragment on NavSection {
        id
        kind
        title
        apps {
          edges {
            node {
              id
            }
          }
        }
        products {
          edges {
            node {
              id
              slug
              status
            }
          }
        }
        ...UpdateNavSectionButtonFragment
        ...DeleteNavSectionButtonFragment
        ...ProductsSidebarList_NavSectionFragment
        ...AppsSidebarList_NavSectionFragment
      }
    `,
    navSectionKey
  )
  const isProducts = navSection.kind === "products"
  const apps = Relay.connectionToArray(navSection.apps)
  let products = Relay.connectionToArray(navSection.products)

  // Hide draft products and therefore sections with only draft products inside them when
  // the admin is viewing as member (unless inside it)
  const isAdminViewingAsMember = useIsAdminViewingAsMember()
  const activeProductSlug = useProductSlug()
  if (isAdminViewingAsMember) {
    products = products.filter((p) => {
      if (activeProductSlug && p.slug === activeProductSlug) return true
      return p.status === "published"
    })
  }

  const canReorder = activeOrganization.viewerPermissions.has(
    isProducts ? "products.manage" : "apps.manage"
  )
  const classes = useStyles({ isDragging, canReorder })
  const { productMemberChannels, communityChannels } = useStreamChat()

  // Allow temporarily forcing the the section to expand then reset the state
  const [forceExpand, setForceExpand] = useState(false)
  useEffect(() => {
    if (forceExpand) setForceExpand(false)
  }, [forceExpand])

  // Don't show empty nav sections to members
  if (!canReorder && !apps.length && !products.length) return null
  if (!activeOrganization.viewerMembership) return null

  const testid = `NavSection.${index}`

  return (
    <>
      {!isDragging && (
        <CreateNavSectionButton navSectionIndex={index} kind={navSection.kind}>
          {(btnProps) => (
            <DiscoDividerButton
              testid={btnProps.testid}
              tooltip={"Add Section"}
              marginTop={0}
              marginBottom={0}
              marginLeft={0}
              marginRight={0}
              showOnHover
              borderWidth={"1px"}
              disabled={!canReorder}
              onClick={btnProps.onClick}
            />
          )}
        </CreateNavSectionButton>
      )}

      <div className={classes.section} data-testid={`${testid}.container`}>
        <CommunitySidebarItem
          testid={testid}
          menuItemClasses={{ root: classes.sideBarItemMenuItem }}
          classes={{ content: classes.sideBarItemContent }}
          name={navSection.title}
          collapsible
          defaultCollapsed={defaultCollapsed}
          dragHandleProps={dragHandleProps}
          nameNode={
            <DiscoText
              variant={"body-sm-500-uppercase"}
              color={
                theme.palette.type === "dark" ? "groovy.onDark.300" : "groovy.neutral.400"
              }
              truncateText={1}
            >
              {navSection.title}
            </DiscoText>
          }
          overflowItems={
            canReorder && (
              <>
                <UpdateNavSectionButton navSectionKey={navSection}>
                  {({ onClick }) => (
                    <DiscoDropdownItem
                      onClick={onClick}
                      testid={`${testid}.edit`}
                      title={"Edit"}
                      icon={"edit"}
                    />
                  )}
                </UpdateNavSectionButton>
                <DeleteNavSectionButton navSectionKey={navSection}>
                  {({ onClick }) => (
                    <DiscoDropdownItem
                      onClick={onClick}
                      testid={`${testid}.remove`}
                      title={"Remove Section"}
                      icon={"trash"}
                    />
                  )}
                </DeleteNavSectionButton>
              </>
            )
          }
          showOnHoverRightContent={renderShowOnHoverRightContent()}
          notificationConfig={getNotificationConfig()}
          forceExpand={forceExpand}
        >
          <div data-testid={`${testid}.children`}>
            {isDragging ? null : isProducts ? (
              <ProductsSidebarList
                testid={`${testid}.products`}
                navSectionKey={navSection}
              />
            ) : (
              <AppsSidebarList navSectionKey={navSection} testid={`${testid}.apps`} />
            )}
          </div>
        </CommunitySidebarItem>
      </div>
    </>
  )

  function renderShowOnHoverRightContent() {
    if (!canReorder) return
    if (isProducts) {
      return (
        <CreateExperienceButton navSectionId={navSection.id}>
          {(buttonProps) => (
            <DiscoTooltip
              content={`Add ${productLabel.singular}`}
              onClick={(e) => {
                // Don't collapse section when clicking this button; force it to expand
                e.stopPropagation()
                setForceExpand(true)
                buttonProps.onClick(e)
              }}
            >
              <DiscoIconButton
                className={classes.addIconButton}
                testid={`NavSection.${index}.add-product`}
              >
                <DiscoIcon icon={"add"} />
              </DiscoIconButton>
            </DiscoTooltip>
          )}
        </CreateExperienceButton>
      )
    }
    return (
      <AppLevelProvider navSectionId={navSection.id}>
        <ActiveAppModalProvider>
          <AddAppButton>
            {(buttonProps) => (
              <DiscoTooltip
                content={"Add App"}
                onClick={(e) => {
                  // Don't collapse section when clicking this button; force it to expand
                  e.stopPropagation()
                  setForceExpand(true)
                  buttonProps.onClick()
                }}
              >
                <DiscoIconButton
                  className={classes.addIconButton}
                  testid={`NavSection.${index}.add-app`}
                >
                  <DiscoIcon icon={"add"} />
                </DiscoIconButton>
              </DiscoTooltip>
            )}
          </AddAppButton>
        </ActiveAppModalProvider>
      </AppLevelProvider>
    )
  }

  function getNotificationConfig() {
    if (isProducts) {
      const channels = productMemberChannels.filter((c) =>
        products.some((p) => p.id === c.productId)
      )
      return {
        filter: {
          productIds: products.map((p) => p.id),
          kinds: [
            "assignment-submitted",
            "course-content-publish",
            "quiz-submitted",
            "survey-submitted",
          ],
        },
        streamChannelIds: channels.map((c) => c.externalChannelId),
      }
    }
    const channels = communityChannels.filter((c) => apps.some((a) => a.id === c.appId))
    return {
      filter: { navSectionId: navSection.id },
      streamChannelIds: channels.map((c) => c.externalChannelId),
    }
  }
}

type StyleProps = {
  isDragging: boolean
  canReorder: boolean
}

const useStyles = makeUseStyles((theme) => ({
  section: ({ isDragging }: StyleProps) => ({
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.measure.borderRadius.big,
    margin: theme.spacing(0.5, 2),
    ...styleIf(isDragging, {
      boxShadow: theme.palette.groovyDepths.raisedBoxShadow,
    }),
  }),
  sideBarItemMenuItem: ({ canReorder }: StyleProps) => ({
    cursor: "unset",
    "&:hover": {
      ...styleIf(!canReorder, {
        backgroundColor: "unset",
      }),
    },
  }),
  sideBarItemContent: {
    gridTemplateColumns: "min-content 1fr min-content",
  },
  addIconButton: {
    padding: theme.spacing(0.375),
    "&:hover": {
      background: "rgba(0,0,0,.1)",
    },
  },
}))

export function NavSectionSkeleton() {
  const classes = useStyles({ isDragging: false, canReorder: false })
  return (
    <>
      <DiscoDivider marginTop={2} marginBottom={2} />
      <div className={classes.section}>
        <DiscoSideBarItemSkeleton />
      </div>
    </>
  )
}

export default NavSection
