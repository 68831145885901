import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { useFormStore } from "@/core/form/store/FormStore"
import { OccurrenceAttendingButtonFragment$key } from "@/organization/occurrence/__generated__/OccurrenceAttendingButtonFragment.graphql"
import { OccurrenceAttendingButtonMutation } from "@/organization/occurrence/__generated__/OccurrenceAttendingButtonMutation.graphql"
import RelayEnvironment from "@/relay/RelayEnvironment"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { displayToast } from "@components/toast/ToastProvider"
import { DiscoButton, DiscoDropdown, DiscoIcon } from "@disco-ui"
import DiscoButtonsGroup from "@disco-ui/button/DiscoButtonsGroup"
import DiscoDropdownItem from "@disco-ui/dropdown/DiscoDropdownItem"
import { useTheme } from "@material-ui/core"
import { TestIDProps } from "@utils/typeUtils"
import { useCallback } from "react"
import { graphql, useFragment } from "react-relay"
import ConnectionHandler from "relay-connection-handler-plus"
import { commitLocalUpdate } from "relay-runtime"

interface OccurrenceAttendingButtonProps extends TestIDProps {
  occurrenceKey: OccurrenceAttendingButtonFragment$key
  disableDropdown?: boolean
}

function OccurrenceAttendingButton({
  occurrenceKey,
  testid = "OccurrenceAttendingButton",
  disableDropdown,
}: OccurrenceAttendingButtonProps) {
  const activeOrganization = useActiveOrganization()!
  const classes = useStyles()
  const theme = useTheme()

  const occurrence = useFragment<OccurrenceAttendingButtonFragment$key>(
    graphql`
      fragment OccurrenceAttendingButtonFragment on Occurrence {
        id
        product {
          id
          slug
          type
        }
      }
    `,
    occurrenceKey
  )

  const form = useFormStore<OccurrenceAttendingButtonMutation>(
    graphql`
      mutation OccurrenceAttendingButtonMutation($input: CancelRSVPToEventInput!) {
        response: cancelRsvpToEvent(input: $input) {
          occurrence {
            id
            hasViewerRSVPd
            atCapacity
            rsvps {
              totalCount
            }
          }
          errors {
            field
            message
          }
        }
      }
    `,
    {}
  )

  const menuButton = useCallback(
    ({ onClick }) => (
      <DiscoButtonsGroup
        noPadding
        classes={{ root: classes.groupRoot }}
        buttons={[
          {
            uniqueKey: "submit-button",
            customClass: classes.attendingButton,
            content: (
              <DiscoButton
                data-testid={`${testid}.attending`}
                disabled={false}
                color={"success"}
              >
                {"Attending"}
              </DiscoButton>
            ),
          },
          {
            uniqueKey: "dropdown-button",
            customClass: classes.dropdownButton,
            content: (
              <DiscoButton
                testid={`${testid}.dropdown-button`}
                onClick={(e) => {
                  e.preventDefault()
                  e.stopPropagation()
                  onClick(e)
                }}
                color={"success"}
                leftIcon={"arrow-down"}
              />
            ),
          },
        ]}
      />
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [testid]
  )

  return disableDropdown ? (
    <DiscoButton
      leftIcon={<DiscoIcon icon={"iconsax.tick-circle"} active />}
      className={classes.disabledButton}
      customButtonColor={{
        backgroundColor: theme.palette.groovy.green[100],
        color: theme.palette.groovy.green[400],
        hover: {
          backgroundColor: theme.palette.groovy.green[100],
          color: theme.palette.groovy.green[400],
        },
      }}
    >
      {"You're Attending"}
    </DiscoButton>
  ) : (
    <DiscoDropdown testid={`${testid}.attending-dropdown`} menuButton={menuButton}>
      <DiscoDropdownItem
        title={"Not Attending"}
        onClick={async () => {
          const { didSave } = await form.submit({ occurrenceId: occurrence.id })

          if (!didSave) return

          displayToast({
            message: "You're no longer attending this event.",
            testid: `OccurrenceAttendingButton.cancel.toast`,
          })

          commitLocalUpdate(RelayEnvironment, (store) => {
            const org = store.get(activeOrganization.id)
            if (!org) return

            ConnectionHandler.getConnections(
              org,
              "RSVPToAllOrganizationEventsButton_occurrences"
            ).forEach((connection) => connection.invalidateRecord())
          })
        }}
        testid={`${testid}.not-attending`}
      />
    </DiscoDropdown>
  )
}

const useStyles = makeUseStyles((theme) => ({
  groupRoot: {
    width: "100%",
  },
  disabledButton: {
    width: "100%",
  },
  attendingButton: {
    width: "100%",
    borderRadius: theme.measure.borderRadius.large,
    "& > span > div, & span, & button": {
      width: "100%",
    },
  },
  dropdownButton: {
    borderRadius: theme.measure.borderRadius.large,
  },
}))

export default OccurrenceAttendingButton
