import ROUTE_NAMES from "@/core/route/util/routeNames"
import { LocationState } from "@/core/route/util/routeUtils"
import {
  OverridableDiscoButton,
  OverridableDiscoButtonChildren,
} from "@disco-ui/button/OverridableDiscoButton"
import { useHistory, useLocation } from "react-router-dom"

type CreateOrganizationButtonProps = {
  children: OverridableDiscoButtonChildren
  testid?: string
}

export default function CreateOrganizationButton(props: CreateOrganizationButtonProps) {
  const { children, testid = "CreateOrganizationButton" } = props
  const location = useLocation<LocationState>()
  const history = useHistory()

  return (
    <OverridableDiscoButton testid={testid} onClick={onClick}>
      {children}
    </OverridableDiscoButton>
  )

  function onClick() {
    history.push({
      ...location,
      pathname: ROUTE_NAMES.ONBOARDING.NEW_COMMUNITY,
      state: { ...location.state, shouldSkipUserSignUp: true },
    })
  }
}
