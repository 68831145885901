import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { useLabel } from "@/core/context/LabelsContext"
import CommunitySidebarAddButton from "@/organization/common/sidebar/CommunitySidebarAddButton"
import { DiscoIcon, DiscoIconButton } from "@disco-ui"
import DiscoSidebarHeader from "@disco-ui/sidebar/DiscoSidebarHeader"

export default function CommunitySidebarHeader() {
  const activeOrganization = useActiveOrganization()!
  const canAdd = activeOrganization.viewerPermissions.has("apps.manage")
  const sidebarCommunity = useLabel("sidebar_community")

  return (
    <DiscoSidebarHeader
      title={sidebarCommunity.singular}
      icon={"home-alt"}
      actions={
        canAdd && (
          <CommunitySidebarAddButton>
            {({ onClick }) => (
              <DiscoIconButton
                size={"small"}
                onClick={(e) => {
                  // Keeps sidebar from closing on mobile which unmounts add app modal
                  e.stopPropagation()
                  onClick(e)
                }}
                testid={"CommunitySidebarHeader.add-button"}
              >
                <DiscoIcon icon={"add"} />
              </DiscoIconButton>
            )}
          </CommunitySidebarAddButton>
        )
      }
    />
  )
}
