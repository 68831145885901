import { useActiveProduct } from "@/core/context/ActiveProductContext"
import { useAuthUser } from "@/core/context/AuthUserContext"
import { useLabels } from "@/core/context/LabelsContext"
import { useFormStore } from "@/core/form/store/FormStore"
import { getRSVPModalDescription } from "@/occurrence/button/rsvp-to-all-button/RSVPToAllOrganizationEventsButton"
import { RSVPToAllProductEventsButtonMutation } from "@/occurrence/button/rsvp-to-all-button/__generated__/RSVPToAllProductEventsButtonMutation.graphql"
import { RSVPToAllProductEventsButtonQuery } from "@/occurrence/button/rsvp-to-all-button/__generated__/RSVPToAllProductEventsButtonQuery.graphql"
import Relay from "@/relay/relayUtils"
import { displaySuccessToast } from "@components/toast/ToastProvider"
import { DiscoButtonSkeleton, DiscoTooltip } from "@disco-ui"
import {
  OverridableDiscoButton,
  OverridableDiscoButtonChildren,
} from "@disco-ui/button/OverridableDiscoButton"
import DiscoWarningModal from "@disco-ui/modal/DiscoWarningModal"
import useDisclosure from "@utils/hook/useDisclosure"
import { useLazyLoadQuery } from "react-relay"
import { graphql } from "relay-runtime"

interface RSVPToAllProductEventsButtonProps {
  children: OverridableDiscoButtonChildren
  showWhenDisabled?: boolean
}

function RSVPToAllProductEventsButton({
  children,
  showWhenDisabled,
}: RSVPToAllProductEventsButtonProps) {
  const activeProduct = useActiveProduct()!
  const { isOpen, onClose, onOpen } = useDisclosure()
  const labels = useLabels()

  const { authUser } = useAuthUser({ required: true })

  const { product } = useLazyLoadQuery<RSVPToAllProductEventsButtonQuery>(
    graphql`
      query RSVPToAllProductEventsButtonQuery($id: ID!) {
        product: node(id: $id) {
          ... on Product {
            id
            viewerMembership {
              id
            }
            eventOccurrences {
              edges {
                node {
                  id
                  isComplete
                  hasViewerRSVPd
                  atCapacity
                }
              }
            }
          }
        }
      }
    `,
    {
      id: activeProduct.id,
    }
  )

  const form = useFormStore<RSVPToAllProductEventsButtonMutation>(
    graphql`
      mutation RSVPToAllProductEventsButtonMutation($input: RSVPToEventInput!) {
        response: rsvpToEvent(input: $input) {
          occurrences {
            edges {
              node {
                id
                hasViewerRSVPd
                atCapacity
                rsvps {
                  totalCount
                }
              }
            }
          }
          errors {
            field
            message
          }
        }
      }
    `,
    {}
  )

  if (!product) return null

  const testid = "RSVPToAllProductEventsButton"

  const occurrences = Relay.connectionToArray(product.eventOccurrences).filter(
    (o) => !o.isComplete
  )

  const unregisteredEvents = occurrences.filter((o) => !o.hasViewerRSVPd && !o.atCapacity)

  // Hide button if user is RSVPd to all upcoming events available or all events are at capacity
  const userRSVPdToAllFutureEvents = unregisteredEvents.length === 0
  const userIsPartOfProduct = !!product.viewerMembership
  const shouldButtonBeDisabled = !userIsPartOfProduct || userRSVPdToAllFutureEvents

  if (shouldButtonBeDisabled && !showWhenDisabled) return null

  return (
    <>
      {shouldButtonBeDisabled ? (
        showWhenDisabled && (
          <DiscoTooltip content={getButtonDisabledReason()}>
            <span>
              <OverridableDiscoButton disabled>{children}</OverridableDiscoButton>
            </span>
          </DiscoTooltip>
        )
      ) : (
        <OverridableDiscoButton onClick={onOpen}>{children}</OverridableDiscoButton>
      )}
      {isOpen && (
        <DiscoWarningModal
          testid={testid}
          isOpen
          variant={"primary"}
          confirmationButtonProps={{
            onClick: handleSubmit,
            shouldDisplaySpinner: form.isSubmitting,
            children: "Attend All",
          }}
          onClose={onClose}
          modalContentLabel={"Confirm attendance to all dialog"}
          title={"Confirm Attendance to All Events?"}
          description={getRSVPModalDescription(authUser.calendarConnection?.platform)}
          icon={"calendar"}
        />
      )}
    </>
  )

  async function handleSubmit() {
    const { didSave } = await form.submit({ productId: activeProduct.id })

    if (didSave) {
      displaySuccessToast({
        message:
          "You confirmed attendance to all events. Check your email for more details!",
        testid: `${testid}.success-toast`,
      })
    }
    onClose()
  }

  function getButtonDisabledReason() {
    if (userRSVPdToAllFutureEvents) return "You are already attending all events!"
    if (!userIsPartOfProduct)
      return `You are not part of this ${labels.admin_experience.singular}. In order to confirm attendance, you need to join the ${labels.admin_experience.singular} first or add yourself as a ${labels.product_admin.singular} or ${labels.product_instructor.singular}.`
    return ""
  }
}

export default Relay.withSkeleton<RSVPToAllProductEventsButtonProps>({
  component: RSVPToAllProductEventsButton,
  skeleton: () => <DiscoButtonSkeleton width={"260px"} />,
})
