import FormStore from "@/core/form/store/FormStore"
import {
  CreateNavSectionButtonMutation,
  CreateNavSectionInput,
} from "@/organization/common/sidebar/nav-section/__generated__/CreateNavSectionButtonMutation.graphql"
import Relay from "@/relay/relayUtils"
import Form from "@components/form/Form"
import { displaySuccessToast } from "@components/toast/ToastProvider"
import { DiscoFormControl, DiscoInput } from "@disco-ui"
import { observer } from "mobx-react-lite"
import ConnectionHandlerPlus from "relay-connection-handler-plus"

type Props = {
  form: FormStore<CreateNavSectionInput, CreateNavSectionButtonMutation>
  onClose: () => void
}

function CreateNavSectionForm({ form, onClose }: Props) {
  return (
    <Form id={"CreateNavSectionForm"} onSubmit={handleSubmit}>
      <DiscoFormControl
        sublabel={"Section Title"}
        error={Boolean(form.errorsByField.title)}
        errorMessages={form.errorsByField.title}
        marginBottom={0}
      >
        <DiscoInput
          data-testid={"CreateNavSectionForm.title"}
          value={form.state.title}
          onChange={(e) => (form.state.title = e.target.value)}
          placeholder={"e.g. Resources"}
        />
      </DiscoFormControl>
    </Form>
  )

  async function handleSubmit() {
    const { didSave } = await form.submit(form.state, {
      updater: (store, { response }) => {
        // Insert the new section into the sidebar connection
        if (!response.node) return
        const sectionRecord = store.get(response.node.id)
        if (!sectionRecord) return
        const orgRecord = store.get(form.state.organizationId)
        if (!orgRecord) return
        ConnectionHandlerPlus.getConnections(
          orgRecord,
          form.state.kind === "products"
            ? "ProductsSidebar__productsNavSections"
            : "CommunitySideBar__communityNavSections"
        ).forEach((connection) => {
          Relay.insertNodeIntoPaginatedConnection(
            store,
            connection,
            sectionRecord,
            form.state.ordering
          )
        })
      },
    })
    if (!didSave) return

    displaySuccessToast({
      message: "Section created!",
      testid: "CreateNavSectionForm.success-toast",
    })
    onClose()
  }
}

export default observer(CreateNavSectionForm)
