import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { useFormStore } from "@/core/form/store/FormStore"
import { OccurrenceMedia } from "@/occurrence/api/occurrenceApiModels"
import UploadEventVideoModalContent from "@/occurrence/live-box/UploadEventVideoModalContent"
import { UploadEventVideoModalMutation } from "@/occurrence/live-box/__generated__/UploadEventVideoModalMutation.graphql"
import { GlobalID } from "@/relay/RelayTypes"
import Form from "@components/form/Form"
import { DiscoButton, DiscoModal } from "@disco-ui"
import { observer } from "mobx-react-lite"
import React from "react"
import { graphql } from "react-relay"

type Props = {
  isOpen: boolean
  occurrenceId: GlobalID
  setOccurrenceMedia?: React.Dispatch<React.SetStateAction<OccurrenceMedia | null>>
  onClose: () => void
}

function UploadEventVideoModal({
  isOpen,
  occurrenceId,
  setOccurrenceMedia,
  onClose,
}: Props) {
  const activeOrganization = useActiveOrganization()

  const form = useFormStore<UploadEventVideoModalMutation>(
    graphql`
      mutation UploadEventVideoModalMutation($input: CreateOccurrenceMediaInput!) {
        response: createOccurrenceMedia(input: $input) {
          node {
            id
            kind
            url
            videoPoster
            occurrence {
              ...EventDrawerRecordingSectionFragment
              ...occurrenceUtils_useOccurrenceStatusFragment
            }
          }
          errors {
            field
            message
          }
        }
      }
    `,
    {
      occurrenceId,
      kind: "video",
      url: "",
      sendNotificationEmail: false,
      notificationEmailBody: "",
      notificationEmailRecipients: undefined,
      organizationId: activeOrganization?.id,
    }
  )

  const canSubmit = calculateCanSubmit()

  return (
    <DiscoModal
      isOpen={isOpen}
      onClose={onClose}
      shouldCloseOnOverlayClick={false}
      shouldCloseOnEsc={false}
      modalContentLabel={"upload event video modal"}
      title={"Upload Event Recording"}
      testid={"UploadEventVideoModal"}
      width={"720px"}
      minHeight={"0"}
      body={
        <UploadEventVideoModalContent
          form={form}
          occurrenceId={occurrenceId}
          setOccurrenceMedia={setOccurrenceMedia}
        />
      }
      buttons={[
        <DiscoButton
          key={"cancel"}
          color={"grey"}
          variant={"outlined"}
          onClick={onClose}
          data-testid={"UploadEventVideoModal.cancel-button"}
        >
          {"Cancel"}
        </DiscoButton>,
        <Form.SubmitButton
          key={"upload-recording"}
          form={form}
          id={"UploadEventVideoForm"}
          disabled={!canSubmit}
          testid={"UploadEventVideoModal.submit-button"}
        >
          {"Upload Recording"}
        </Form.SubmitButton>,
      ]}
    />
  )

  function calculateCanSubmit() {
    // Want to send email but email notification body not filled
    if (form.state.sendNotificationEmail && !form.state.notificationEmailBody)
      return false
    // No video uploaded
    if (!form.state.url) return false

    return !form.isSubmitting
  }
}

export default observer(UploadEventVideoModal)
