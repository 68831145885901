import { useActiveProduct } from "@/core/context/ActiveProductContext"
import { useLabel } from "@/core/context/LabelsContext"
import CalendarIcon from "@/core/ui/iconsax/linear/calendar.svg"
import CalendarEmptyIcon from "@/core/ui/images/empty-state/calendar.svg"
import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import RSVPToAllProductEventsButton from "@/occurrence/button/rsvp-to-all-button/RSVPToAllProductEventsButton"
import MemberGroupEventsListItem, {
  MemberGroupEventsListItemSkeleton,
} from "@/occurrence/calendar/modal/list/item/MemberGroupEventsListItem"
import { MemberGroupEventsListQuery } from "@/occurrence/calendar/modal/list/__generated__/MemberGroupEventsListQuery.graphql"
import Relay from "@/relay/relayUtils"
import { DiscoButton, DiscoEmptyState, DiscoText } from "@disco-ui"
import React from "react"
import { graphql, useLazyLoadQuery } from "react-relay"

const MemberGroupEventsList: React.FC = () => {
  const classes = useStyles()

  const activeProduct = useActiveProduct()!
  const experienceLabel = useLabel("experience")

  const { product } = useLazyLoadQuery<MemberGroupEventsListQuery>(
    graphql`
      query MemberGroupEventsListQuery($id: ID!) {
        product: node(id: $id) {
          ... on Product {
            id
            eventOccurrences(isUpcoming: true) {
              edges {
                node {
                  id
                  ...MemberGroupEventsListItemFragment
                }
              }
            }
          }
        }
      }
    `,
    { id: activeProduct.id },
    { fetchPolicy: "network-only" }
  )

  const occurrences = Relay.connectionToArray(product?.eventOccurrences)
  if (!occurrences.length) {
    return (
      <DiscoEmptyState
        testid={"MemberGroupEventsList"}
        icon={<CalendarEmptyIcon />}
        title={"No Events"}
        subtitle={`This ${experienceLabel.singular} either has no events or all events have passed. Please check back later.`}
      />
    )
  }

  return (
    <>
      <DiscoText color={"text.secondary"}>
        {
          "Confirm attendance and add events to your calendar now to block off the time so you won't miss any! A reminder email will also be sent 24 hours ahead of each event."
        }
      </DiscoText>
      <RSVPToAllProductEventsButton showWhenDisabled>
        {(buttonProps) => (
          <DiscoButton
            {...buttonProps}
            className={classes.attendAllButton}
            leftIcon={<CalendarIcon />}
            color={"grey"}
            variant={"outlined"}
            testid={"MemberGroupEventsList.RSVPToAllProductEventsButton"}
          >
            {"Attend All"}
          </DiscoButton>
        )}
      </RSVPToAllProductEventsButton>
      <ul className={classes.list}>
        {occurrences.map((occurrence, i) => (
          <MemberGroupEventsListItem
            key={occurrence.id}
            occurrenceKey={occurrence}
            testid={`MemberGroupEventsListItem.${i}`}
          />
        ))}
      </ul>
    </>
  )
}

const useStyles = makeUseStyles((theme) => ({
  attendAllButton: {
    marginTop: theme.spacing(2),
    width: "100%",
  },
  list: {
    paddingBottom: theme.spacing(4),
  },
}))

const MemberGroupEventsListSkeleton: React.FC = () => {
  return (
    <ul>
      <MemberGroupEventsListItemSkeleton />
      <MemberGroupEventsListItemSkeleton />
      <MemberGroupEventsListItemSkeleton />
    </ul>
  )
}

export default Relay.withSkeleton({
  component: MemberGroupEventsList,
  skeleton: MemberGroupEventsListSkeleton,
})
