import { GlobalDrawerParams } from "@/core/context/GlobalDrawerProvider"
import { useOccurrenceStatus } from "@/occurrence/util/occurrenceUtils"
import { OccurrenceWatchVideoButtonFragment$key } from "@/organization/occurrence/__generated__/OccurrenceWatchVideoButtonFragment.graphql"
import {
  OverridableDiscoButton,
  OverridableDiscoButtonChildren,
} from "@disco-ui/button/OverridableDiscoButton"
import { setSearchParams } from "@utils/url/urlUtils"
import { graphql, useFragment } from "react-relay"
import { useHistory } from "react-router-dom"

type OccurrenceWatchVideoButtonProps = {
  occurrenceKey: OccurrenceWatchVideoButtonFragment$key
  children: OverridableDiscoButtonChildren
}

function OccurrenceWatchVideoButton({
  occurrenceKey,
  children,
  ...rest
}: OccurrenceWatchVideoButtonProps) {
  const history = useHistory()

  const occurrence = useFragment<OccurrenceWatchVideoButtonFragment$key>(
    graphql`
      fragment OccurrenceWatchVideoButtonFragment on Occurrence {
        id
        ...occurrenceUtils_useOccurrenceStatusFragment
      }
    `,
    occurrenceKey
  )

  const status = useOccurrenceStatus(occurrence)
  if (status !== "ended-watch-recording") return null

  return (
    <>
      <OverridableDiscoButton
        onClick={(e) => {
          // needed to prevent propagation on OrganizationOccurrenceListItem
          // wrapping link (opens EventDrawer)
          e.stopPropagation()
          e.preventDefault()

          handleClick()
        }}
        {...rest}
      >
        {children}
      </OverridableDiscoButton>
    </>
  )

  function handleClick() {
    history.push({
      ...location,
      search: setSearchParams<GlobalDrawerParams<"event">>(location.search, {
        drawerOccurrenceId: occurrence.id,
        drawerEventTab: "details",
        startRecording: "true",
      }),
    })
  }
}

export default OccurrenceWatchVideoButton
