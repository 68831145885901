import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { EventsSidebarProductFiltersQuery } from "@/occurrence/sidebar/__generated__/EventsSidebarProductFiltersQuery.graphql"
import { EventsCalendarQueryParams } from "@/organization/occurrence/OrganizationOccurrenceList"
import Relay from "@/relay/relayUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { useQueryParamState } from "@disco-ui/tabs/DiscoQueryParamTabs"
import DiscoTag from "@disco-ui/tag/DiscoTag"
import { useEffect, useState } from "react"
import { graphql } from "relay-runtime"

function EventsSidebarProductFilters() {
  const activeOrganization = useActiveOrganization()!
  const [params, setParams] = useQueryParamState<EventsCalendarQueryParams>()

  const productIds = params.productIds?.split(",") || []

  const classes = useStyles()

  const [{ organization }, refetch] =
    Relay.useRefetchableQuery<EventsSidebarProductFiltersQuery>(
      graphql`
        query EventsSidebarProductFiltersQuery($id: ID!, $productIds: [ID!]!) {
          organization: node(id: $id) {
            ... on Organization {
              products(ids: $productIds, type: "course") {
                edges {
                  node {
                    id
                    name
                  }
                }
              }
            }
          }
        }
      `,
      { id: activeOrganization.id, productIds },
      { refetchInBackground: true }
    )

  const products = Relay.connectionToArray(organization?.products)

  const [loadedProducts, setLoadedProducts] = useState(products)

  useEffect(() => {
    if (productIds.every((id) => loadedProducts.some((product) => product.id === id)))
      return
    refetch({ id: activeOrganization.id, productIds })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.productIds])

  useEffect(() => {
    setLoadedProducts(products)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(products)])

  return (
    <>
      {loadedProducts
        .filter((product) => productIds.includes(product.id))
        .map((product) => {
          return (
            <DiscoTag
              key={product.id}
              testid={`EventsSidebar.active-filter.product-${product.name}`}
              name={product.name}
              className={classes.filterFullWidth}
              onDelete={() => removeProductId(product.id)}
            />
          )
        })}
    </>
  )

  function removeProductId(productId: string) {
    setParams({ productIds: productIds.filter((id) => id !== productId).join(",") })
  }
}

const useStyles = makeUseStyles({
  filterFullWidth: {
    maxWidth: "100%",
  },
})

export default Relay.withSkeleton({
  component: EventsSidebarProductFilters,
  skeleton: () => null,
})
