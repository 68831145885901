import NavFolderSettingsButton from "@/apps/add-nav-folder-button/NavFolderSettingsButton"
import DeleteNavFolderButton from "@/organization/common/topbar/nav-folder/DeleteNavFolderButton"
import { NavFolderSettingsDropdownFragment$key } from "@/organization/common/topbar/nav-folder/__generated__/NavFolderSettingsDropdownFragment.graphql"
import { DiscoIcon } from "@disco-ui"
import DiscoDropdownItem from "@disco-ui/dropdown/DiscoDropdownItem"
import usePermissions from "@utils/hook/usePermissions"
import { graphql, useFragment } from "react-relay"

interface NavFolderSettingsDropdownProps {
  appKey: NavFolderSettingsDropdownFragment$key
}

export default function NavFolderSettingsDropdown({
  appKey,
}: NavFolderSettingsDropdownProps) {
  const navFolder = useFragment<NavFolderSettingsDropdownFragment$key>(
    graphql`
      fragment NavFolderSettingsDropdownFragment on ProductApp {
        id
        product {
          ...usePermissionsFragment
        }
        ...NavFolderSettingsButtonFragment
        ...RemoveAppButtonFragment
        ...DeleteNavFolderButtonFragment
      }
    `,
    appKey
  )
  const permissions = usePermissions(navFolder.product)

  if (!permissions.has("apps.manage")) return null

  return (
    <>
      <NavFolderSettingsButton appKey={navFolder}>
        {(buttonProps) => {
          return (
            <DiscoDropdownItem
              {...buttonProps}
              testid={"NavFolderSettingsDropdown.more-actions.edit"}
              title={"Edit Folder"}
              icon={<DiscoIcon icon={"pencil"} />}
            />
          )
        }}
      </NavFolderSettingsButton>
      <DeleteNavFolderButton navFolderKey={navFolder}>
        {(buttonProps) => (
          <DiscoDropdownItem
            {...buttonProps}
            icon={<DiscoIcon icon={"trash"} />}
            testid={`NavFolderSettingsDropdown.more-actions.remove`}
            title={"Remove"}
          />
        )}
      </DeleteNavFolderButton>
    </>
  )
}
