/**
 * @generated SignedSource<<057a2366eff9991d544b8e9b4f231d70>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type OccurrenceMediaKind = "video" | "image" | "%future added value";
export type OccurrenceMediaRecipients = "all" | "attended" | "not_attended" | "%future added value";
export type CreateOccurrenceMediaInput = {
  occurrenceId: string;
  kind: OccurrenceMediaKind;
  url: string;
  mediaAssetId?: string | null;
  videoPoster?: string | null;
  videoPosterAssetId?: string | null;
  sendNotificationEmail: boolean;
  notificationEmailBody?: string | null;
  notificationEmailRecipients?: OccurrenceMediaRecipients | null;
  organizationId?: string | null;
};
export type UploadEventVideoModalMutation$variables = {
  input: CreateOccurrenceMediaInput;
};
export type UploadEventVideoModalMutation$data = {
  readonly response: {
    readonly node: {
      readonly id: string;
      readonly kind: OccurrenceMediaKind;
      readonly url: string;
      readonly videoPoster: string | null;
      readonly occurrence: {
        readonly " $fragmentSpreads": FragmentRefs<"EventDrawerRecordingSectionFragment" | "occurrenceUtils_useOccurrenceStatusFragment">;
      };
    } | null;
    readonly errors: ReadonlyArray<{
      readonly field: string;
      readonly message: string;
    }> | null;
  };
};
export type UploadEventVideoModalMutation = {
  variables: UploadEventVideoModalMutation$variables;
  response: UploadEventVideoModalMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "kind",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "videoPoster",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "ValidationError",
  "kind": "LinkedField",
  "name": "errors",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "field",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "message",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v7 = [
  (v2/*: any*/)
],
v8 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "viewerPermissions",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UploadEventVideoModalMutation",
    "selections": [
      {
        "alias": "response",
        "args": (v1/*: any*/),
        "concreteType": "CreateOccurrenceMediaResponse",
        "kind": "LinkedField",
        "name": "createOccurrenceMedia",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "OccurrenceMedia",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Occurrence",
                "kind": "LinkedField",
                "name": "occurrence",
                "plural": false,
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "EventDrawerRecordingSectionFragment"
                  },
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "occurrenceUtils_useOccurrenceStatusFragment"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v6/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UploadEventVideoModalMutation",
    "selections": [
      {
        "alias": "response",
        "args": (v1/*: any*/),
        "concreteType": "CreateOccurrenceMediaResponse",
        "kind": "LinkedField",
        "name": "createOccurrenceMedia",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "OccurrenceMedia",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Occurrence",
                "kind": "LinkedField",
                "name": "occurrence",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "eventId",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "coverPhoto",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": [
                      {
                        "kind": "Literal",
                        "name": "first",
                        "value": 1
                      }
                    ],
                    "concreteType": "OccurrenceMediaNodeConnection",
                    "kind": "LinkedField",
                    "name": "media",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "OccurrenceMediaNodeEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "OccurrenceMedia",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/),
                              (v3/*: any*/),
                              (v4/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "AssetUsage",
                                "kind": "LinkedField",
                                "name": "mediaAssetUsage",
                                "plural": false,
                                "selections": (v7/*: any*/),
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Asset",
                                "kind": "LinkedField",
                                "name": "asset",
                                "plural": false,
                                "selections": (v7/*: any*/),
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": "media(first:1)"
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Content",
                    "kind": "LinkedField",
                    "name": "content",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "productId",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "datetimeRange",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasViewerRSVPd",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "timezone",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Product",
                    "kind": "LinkedField",
                    "name": "product",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ProductMembership",
                        "kind": "LinkedField",
                        "name": "viewerMembership",
                        "plural": false,
                        "selections": (v7/*: any*/),
                        "storageKey": null
                      },
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Event",
                    "kind": "LinkedField",
                    "name": "event",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__typename",
                            "storageKey": null
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": (v8/*: any*/),
                            "type": "Organization",
                            "abstractKey": null
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": (v8/*: any*/),
                            "type": "Product",
                            "abstractKey": null
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": (v8/*: any*/),
                            "type": "Event",
                            "abstractKey": null
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": (v8/*: any*/),
                            "type": "Comment",
                            "abstractKey": null
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": (v8/*: any*/),
                            "type": "Feed",
                            "abstractKey": null
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": (v8/*: any*/),
                            "type": "Post",
                            "abstractKey": null
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": (v8/*: any*/),
                            "type": "ContentUsage",
                            "abstractKey": null
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": (v8/*: any*/),
                            "type": "ChatChannel",
                            "abstractKey": null
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": (v8/*: any*/),
                            "type": "Dashboard",
                            "abstractKey": null
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": (v8/*: any*/),
                            "type": "AssignmentSubmission",
                            "abstractKey": null
                          }
                        ],
                        "type": "Node",
                        "abstractKey": "__isNode"
                      },
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v6/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "fd39be8d7bfaa40eaf6ad1eae7ab6724",
    "id": null,
    "metadata": {},
    "name": "UploadEventVideoModalMutation",
    "operationKind": "mutation",
    "text": "mutation UploadEventVideoModalMutation(\n  $input: CreateOccurrenceMediaInput!\n) {\n  response: createOccurrenceMedia(input: $input) {\n    node {\n      id\n      kind\n      url\n      videoPoster\n      occurrence {\n        ...EventDrawerRecordingSectionFragment\n        ...occurrenceUtils_useOccurrenceStatusFragment\n        id\n      }\n    }\n    errors {\n      field\n      message\n    }\n  }\n}\n\nfragment EventDrawerRecordingSectionFragment on Occurrence {\n  id\n  eventId\n  coverPhoto\n  media(first: 1) {\n    edges {\n      node {\n        id\n        kind\n        url\n        mediaAssetUsage {\n          id\n        }\n        asset {\n          id\n        }\n      }\n    }\n  }\n  content {\n    id\n    productId\n  }\n}\n\nfragment occurrenceUtils_useOccurrenceStatusFragment on Occurrence {\n  id\n  datetimeRange\n  hasViewerRSVPd\n  timezone\n  product {\n    viewerMembership {\n      id\n    }\n    id\n  }\n  media(first: 1) {\n    edges {\n      node {\n        id\n      }\n    }\n  }\n  event {\n    ...usePermissionsFragment\n    id\n  }\n}\n\nfragment usePermissionsFragment on Node {\n  __isNode: __typename\n  __typename\n  ... on Organization {\n    viewerPermissions\n  }\n  ... on Product {\n    viewerPermissions\n  }\n  ... on Event {\n    viewerPermissions\n  }\n  ... on Comment {\n    viewerPermissions\n  }\n  ... on Feed {\n    viewerPermissions\n  }\n  ... on Post {\n    viewerPermissions\n  }\n  ... on ContentUsage {\n    viewerPermissions\n  }\n  ... on ChatChannel {\n    viewerPermissions\n  }\n  ... on Dashboard {\n    viewerPermissions\n  }\n  ... on AssignmentSubmission {\n    viewerPermissions\n  }\n}\n"
  }
};
})();

(node as any).hash = "a252f7099dd4b0b51c000a75819c2c24";

export default node;
