import ROUTE_NAMES from "@/core/route/util/routeNames"
import EventsJumpToDateCalendar from "@/occurrence/sidebar/EventsJumpToDateCalendar"
import EventsSidebarFilterDropdown from "@/occurrence/sidebar/EventsSidebarFilterDropdown"
import EventsSidebarFilters from "@/occurrence/sidebar/EventsSidebarFilters"
import CommunitySidebarItem from "@/organization/common/sidebar/CommunitySidebarItem"
import {
  EventsCalendarQueryParams,
  EventsFilterOption,
} from "@/organization/occurrence/OrganizationOccurrenceList"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import {
  DiscoDivider,
  DiscoIcon,
  DiscoIconKinds,
  DiscoSideBar,
  DiscoSideBarProps,
  DiscoText,
} from "@disco-ui"
import DiscoSidebarHeader from "@disco-ui/sidebar/DiscoSidebarHeader"
import { useQueryParamState } from "@disco-ui/tabs/DiscoQueryParamTabs"

type EventTab = {
  name: string
  icon: DiscoIconKinds
  tab: EventsFilterOption
}

export default function EventsSidebar(props: Partial<DiscoSideBarProps>) {
  const classes = useStyles()
  const [params] = useQueryParamState<EventsCalendarQueryParams>()

  const tabs: EventTab[] = [
    {
      name: "All Events",
      icon: "ticket",
      tab: "all",
    },
    {
      name: "Events with Recordings",
      icon: "video-square",
      tab: "recordings",
    },
  ]

  return (
    <DiscoSideBar
      {...props}
      fullHeight
      data-testid={"EventsSidebar"}
      header={
        <DiscoSidebarHeader
          title={"Events"}
          icon={"calendar"}
          actions={<EventsSidebarFilterDropdown />}
        />
      }
      items={
        <div>
          <div className={classes.items}>
            {tabs.map((tab) => {
              const isActive =
                tab.tab === params.calendarTab ||
                (tab.tab === "all" && !params.calendarTab)
              return (
                <CommunitySidebarItem
                  key={tab.tab}
                  testid={`EventsSidebar.${tab.tab}`}
                  name={tab.name}
                  leftIcon={<DiscoIcon icon={tab.icon} active={isActive} />}
                  to={`${ROUTE_NAMES.COMMUNITY.EVENTS_CALENDAR.ROOT}?calendarTab=${tab.tab}`}
                  selected={isActive}
                />
              )
            })}
          </div>
          <EventsSidebarFilters />
          <DiscoDivider />
          <DiscoText
            variant={"body-sm-500"}
            marginTop={2}
            paddingLeft={2}
            color={"groovy.neutral.500"}
          >
            {"Jump to a specific date:"}
          </DiscoText>
          <EventsJumpToDateCalendar testid={"EventsSidebar"} />
        </div>
      }
    />
  )
}

const useStyles = makeUseStyles((theme) => ({
  items: {
    padding: theme.spacing(2),
  },
}))
