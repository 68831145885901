/**
 * @generated SignedSource<<2d76c7996d3a2c9f414c5934adf08c44>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DeleteOccurrenceButtonFragment$data = {
  readonly id: string;
  readonly " $fragmentType": "DeleteOccurrenceButtonFragment";
};
export type DeleteOccurrenceButtonFragment$key = {
  readonly " $data"?: DeleteOccurrenceButtonFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"DeleteOccurrenceButtonFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DeleteOccurrenceButtonFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "Occurrence",
  "abstractKey": null
};

(node as any).hash = "fd66ebb26525ebe32fae89610ec21339";

export default node;
