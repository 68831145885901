/**
 * @generated SignedSource<<0da2bac01e10b41b4c88679b553b77f8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type NavSectionKind = "products" | "custom" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type DeleteNavSectionButtonFragment$data = {
  readonly id: string;
  readonly title: string;
  readonly kind: NavSectionKind;
  readonly ordering: number;
  readonly organizationId: string;
  readonly " $fragmentType": "DeleteNavSectionButtonFragment";
};
export type DeleteNavSectionButtonFragment$key = {
  readonly " $data"?: DeleteNavSectionButtonFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"DeleteNavSectionButtonFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DeleteNavSectionButtonFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "kind",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "ordering",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "organizationId",
      "storageKey": null
    }
  ],
  "type": "NavSection",
  "abstractKey": null
};

(node as any).hash = "cb5504553798e0edc74121c1e0cb158c";

export default node;
