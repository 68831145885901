import { isAdminConsolePath } from "@/core/route/util/routeUtils"
import AdminDashboardDropdown from "@/main/page/header/buttons/AdminDashboardDropdown"
import OrganizationLogoWithFallbackName from "@/main/page/header/OrganizationLogoWithFallbackName"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { Grid } from "@material-ui/core"
import { ClassNameMap } from "@material-ui/core/styles/withStyles"
import { TestIDProps } from "@utils/typeUtils"
import classNames from "classnames"
import { useLocation } from "react-router-dom"

interface SideBarHeaderProps extends TestIDProps {
  hideDashboardButton?: boolean
  classes?: ClassNameMap<"container">
}
function SideBarHeader({
  testid = "SideBarHeader",
  hideDashboardButton = false,
  classes: customClasses,
}: SideBarHeaderProps) {
  const { pathname } = useLocation()
  const isOnAdmin = isAdminConsolePath(pathname)
  const classes = useStyles()
  return (
    <Grid
      data-testid={testid}
      container
      alignItems={"center"}
      className={classNames(classes.container, customClasses?.container)}
    >
      <Grid item xs zeroMinWidth>
        <OrganizationLogoWithFallbackName />
      </Grid>
      {!isOnAdmin && !hideDashboardButton && <AdminDashboardDropdown />}
    </Grid>
  )
}
const useStyles = makeUseStyles({
  container: {
    width: "100%",
  },
})

export function SideBarHeaderSkeleton() {
  const classes = useStyles()

  return (
    <Grid container alignItems={"center"} className={classes.container}>
      <Grid item xs zeroMinWidth>
        <OrganizationLogoWithFallbackName />
      </Grid>
    </Grid>
  )
}

export default SideBarHeader
