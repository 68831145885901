import ROUTE_NAMES from "@/core/route/util/routeNames"
import { generatePath } from "react-router-dom"

function generateCoursePaths(productSlug: string) {
  return {
    ROOT: generatePath(ROUTE_NAMES.PRODUCT.ROOT, { productSlug }),
    DASHBOARD: generatePath(ROUTE_NAMES.PRODUCT.DASHBOARD, {
      productSlug,
    }),
    CURRICULUM: generatePaths({
      ROOT: ROUTE_NAMES.PRODUCT.CURRICULUM.ROOT,
      OVERVIEW: ROUTE_NAMES.PRODUCT.CURRICULUM.OVERVIEW,
    }),
    EVENTS: generatePath(ROUTE_NAMES.PRODUCT.EVENTS.LIST.ROOT, {
      productSlug,
    }),
    RESOURCES: generatePath(ROUTE_NAMES.PRODUCT.RESOURCES.LIST, {
      productSlug,
    }),
    MEMBERS: generatePaths(ROUTE_NAMES.PRODUCT.MEMBERS),
    REPORTS: generatePath(ROUTE_NAMES.PRODUCT.REPORTS.PROGRESS.ROOT, {
      productSlug,
    }),
    LANDING_PAGE: generatePaths({
      EDIT: ROUTE_NAMES.PRODUCT.LANDING_PAGE.EDIT,
    }),
    CERTIFICATES_ROOT: generatePath(ROUTE_NAMES.PRODUCT.CERTIFICATES.ROOT, {
      productSlug,
    }),
  }

  function generatePaths<T extends Record<string, string>>(
    routes: T
  ): Record<keyof T, string> {
    return Object.keys(routes).reduce((map, key) => {
      map[key as keyof T] = generatePath(routes[key], { productSlug })
      return map
    }, {} as Record<keyof T, string>)
  }
}

export default generateCoursePaths
