import CalendarIcon from "@/core/ui/iconsax/linear/calendar.svg"
import { OccurrenceRegisterAndRSVPButtonFragment$key } from "@/organization/occurrence/__generated__/OccurrenceRegisterAndRSVPButtonFragment.graphql"
import { DiscoButton, DiscoButtonProps } from "@disco-ui"
import { setSearchParams } from "@utils/url/urlUtils"
import { graphql, useFragment } from "react-relay"

type OccurrenceRegisterAndRSVPButtonProps = {
  occurrenceKey: OccurrenceRegisterAndRSVPButtonFragment$key
  children?: string
  testid?: string
  onClick?: () => void
} & Pick<DiscoButtonProps, "color" | "size" | "width">

function OccurrenceRegisterAndRSVPButton({
  occurrenceKey,
  onClick,
  children = "Register & RSVP",
  testid = "OccurrenceRegisterAndRSVPButton",
  ...buttonProps
}: OccurrenceRegisterAndRSVPButtonProps) {
  const occurrence = useFragment<OccurrenceRegisterAndRSVPButtonFragment$key>(
    graphql`
      fragment OccurrenceRegisterAndRSVPButtonFragment on Occurrence {
        id
        product {
          id
          slug
          type
        }
      }
    `,
    occurrenceKey
  )

  return (
    <DiscoButton
      leftIcon={<CalendarIcon />}
      to={{
        pathname: location.pathname,
        search: setSearchParams(location.search, {
          drawerRegistrationExperienceId: occurrence.product.id,
          ...(occurrence.product.type === "community_event" && {
            drawerRegistrationOccurrenceId: occurrence.id,
          }),
        }),
        state: {
          checkoutBacklinkUrl: `${location.origin}/events-calendar?drawerOccurrenceId=${occurrence.id}`,
        },
      }}
      onClick={onClick}
      color={"grey"}
      variant={"outlined"}
      testid={testid}
      width={"100%"}
      {...buttonProps}
    >
      {children}
    </DiscoButton>
  )
}

export default OccurrenceRegisterAndRSVPButton
