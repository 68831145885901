import { useOccurrenceStatus } from "@/occurrence/util/occurrenceUtils"
import OccurrenceAttendingButton from "@/organization/occurrence/OccurrenceAttendingButton"
import OccurrenceRegisterAndRSVPButton from "@/organization/occurrence/OccurrenceRegisterAndRSVPButton"
import { OccurrenceRSVPAttendanceStatusFragment$key } from "@/organization/occurrence/__generated__/OccurrenceRSVPAttendanceStatusFragment.graphql"
import Relay from "@/relay/relayUtils"
import RSVPToEventButton from "@components/buttons/RSVPToEventButton"
import JoinEventButton from "@components/join-event-button/JoinEventButton"
import { DiscoButtonProps, DiscoButtonSkeleton } from "@disco-ui"
import { graphql, useFragment } from "react-relay"

type OccurrenceRSVPAttendanceStatusProps = {
  occurrenceKey: OccurrenceRSVPAttendanceStatusFragment$key
  onJoinEvent?: VoidFunction
  onRegistration?: VoidFunction
  enableRecurringEventConfirmModal?: boolean
  hideJoinButton?: boolean
} & Pick<DiscoButtonProps, "width">

function OccurrenceRSVPAttendanceStatus({
  occurrenceKey,
  onJoinEvent,
  onRegistration,
  enableRecurringEventConfirmModal = false,
  width,
  hideJoinButton = false,
}: OccurrenceRSVPAttendanceStatusProps) {
  const occurrence = useFragment<OccurrenceRSVPAttendanceStatusFragment$key>(
    graphql`
      fragment OccurrenceRSVPAttendanceStatusFragment on Occurrence {
        id
        product {
          id
          slug
          type
        }
        ...RSVPToEventButtonFragment
        ...occurrenceUtils_useOccurrenceStatusFragment
        ...OccurrenceRegisterAndRSVPButtonFragment
        ...OccurrenceAttendingButtonFragment
      }
    `,
    occurrenceKey
  )

  const status = useOccurrenceStatus(occurrence)
  const isCommunityEvent = occurrence.product.type === "community_event"

  switch (status) {
    case "register":
    case "register-while-live":
      if (isCommunityEvent) {
        return (
          <RSVPToEventButton
            occurrenceKey={occurrence}
            text={"Attend"}
            enableRecurringEventConfirmModal={enableRecurringEventConfirmModal}
            width={width}
          />
        )
      }
      return (
        <OccurrenceRegisterAndRSVPButton
          occurrenceKey={occurrence}
          onClick={onRegistration}
          width={width}
        />
      )
    case "rsvp":
    case "rsvp-while-live":
      return (
        <RSVPToEventButton
          occurrenceKey={occurrence}
          text={"Attend"}
          enableRecurringEventConfirmModal={enableRecurringEventConfirmModal}
          width={width}
        />
      )
    case "attending":
      return (
        <OccurrenceAttendingButton
          testid={"OccurrenceRSVPAttendanceStatus"}
          occurrenceKey={occurrence}
        />
      )
    case "live-in-hour":
    case "live":
      if (hideJoinButton) return null
      return (
        <JoinEventButton
          occurrenceId={occurrence.id}
          testid={"OccurrenceRSVPAttendanceStatus.join-event-button"}
          onClick={onJoinEvent}
          width={width}
        />
      )
    case "ended":
    case "ended-watch-recording":
    case "ended-upload-recording":
    default:
      return null
  }
}

function OccurrenceRSVPAttendanceStatusSkeleton() {
  return <DiscoButtonSkeleton width={"100px"} />
}

export default Relay.withSkeleton({
  component: OccurrenceRSVPAttendanceStatus,
  skeleton: OccurrenceRSVPAttendanceStatusSkeleton,
})
