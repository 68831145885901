/**
 * @generated SignedSource<<9050da6f9d07149ecbee7a1b34a8d00b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type OrganizationOccurrencesDatetimeFilter = "upcoming" | "past" | "%future added value";
export type EventsJumpToDateCalendarQuery$variables = {
  id: string;
  startsAfter: string;
  startsBefore: string;
  datetimeFilter?: OrganizationOccurrencesDatetimeFilter | null;
  excludeCommunityEvents?: boolean | null;
  includedProductIds?: ReadonlyArray<string> | null;
  hasRecording?: boolean | null;
};
export type EventsJumpToDateCalendarQuery$data = {
  readonly organization: {
    readonly occurrences?: {
      readonly __id: string;
      readonly totalCount: number;
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
          readonly datetimeRange: ReadonlyArray<string>;
        };
      }>;
      readonly pageInfo: {
        readonly startCursor: string | null;
        readonly endCursor: string | null;
        readonly hasNextPage: boolean;
        readonly hasPreviousPage: boolean;
      };
    };
  } | null;
};
export type EventsJumpToDateCalendarQuery = {
  variables: EventsJumpToDateCalendarQuery$variables;
  response: EventsJumpToDateCalendarQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "datetimeFilter"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "excludeCommunityEvents"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "hasRecording"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "includedProductIds"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "startsAfter"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "startsBefore"
},
v7 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v8 = {
  "kind": "Variable",
  "name": "datetimeFilter",
  "variableName": "datetimeFilter"
},
v9 = {
  "kind": "Variable",
  "name": "excludeCommunityEvents",
  "variableName": "excludeCommunityEvents"
},
v10 = {
  "kind": "Variable",
  "name": "hasRecording",
  "variableName": "hasRecording"
},
v11 = {
  "kind": "Variable",
  "name": "includedProductIds",
  "variableName": "includedProductIds"
},
v12 = {
  "kind": "Literal",
  "name": "onlyDistinctDates",
  "value": true
},
v13 = {
  "kind": "Variable",
  "name": "startsAfter",
  "variableName": "startsAfter"
},
v14 = {
  "kind": "Variable",
  "name": "startsBefore",
  "variableName": "startsBefore"
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v17 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "totalCount",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "OccurrenceNodeEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Occurrence",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v15/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "datetimeRange",
            "storageKey": null
          },
          (v16/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "cursor",
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "PageInfoObjectType",
    "kind": "LinkedField",
    "name": "pageInfo",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "startCursor",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "endCursor",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "hasNextPage",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "hasPreviousPage",
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "kind": "ClientExtension",
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "__id",
        "storageKey": null
      }
    ]
  }
],
v18 = [
  (v8/*: any*/),
  (v9/*: any*/),
  {
    "kind": "Literal",
    "name": "first",
    "value": 50
  },
  (v10/*: any*/),
  (v11/*: any*/),
  (v12/*: any*/),
  (v13/*: any*/),
  (v14/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "EventsJumpToDateCalendarQuery",
    "selections": [
      {
        "alias": "organization",
        "args": (v7/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": "occurrences",
                "args": [
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/),
                  (v14/*: any*/)
                ],
                "concreteType": "OccurrenceNodeConnection",
                "kind": "LinkedField",
                "name": "__EventsJumpToDateCalendar__occurrences_connection",
                "plural": false,
                "selections": (v17/*: any*/),
                "storageKey": null
              }
            ],
            "type": "Organization",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v3/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/),
      (v4/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "EventsJumpToDateCalendarQuery",
    "selections": [
      {
        "alias": "organization",
        "args": (v7/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v16/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": (v18/*: any*/),
                "concreteType": "OccurrenceNodeConnection",
                "kind": "LinkedField",
                "name": "occurrences",
                "plural": false,
                "selections": (v17/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v18/*: any*/),
                "filters": [
                  "startsBefore",
                  "startsAfter",
                  "datetimeFilter",
                  "excludeCommunityEvents",
                  "includedProductIds",
                  "hasRecording",
                  "onlyDistinctDates"
                ],
                "handle": "connection",
                "key": "EventsJumpToDateCalendar__occurrences",
                "kind": "LinkedHandle",
                "name": "occurrences"
              }
            ],
            "type": "Organization",
            "abstractKey": null
          },
          (v15/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "c88b381a865c7c06d8f9019c455400f1",
    "id": null,
    "metadata": {
      "connection": [
        {
          "count": null,
          "cursor": null,
          "direction": "forward",
          "path": [
            "organization",
            "occurrences"
          ]
        }
      ]
    },
    "name": "EventsJumpToDateCalendarQuery",
    "operationKind": "query",
    "text": "query EventsJumpToDateCalendarQuery(\n  $id: ID!\n  $startsAfter: DateTime!\n  $startsBefore: DateTime!\n  $datetimeFilter: OrganizationOccurrencesDatetimeFilter\n  $excludeCommunityEvents: Boolean\n  $includedProductIds: [ID!]\n  $hasRecording: Boolean\n) {\n  organization: node(id: $id) {\n    __typename\n    ... on Organization {\n      occurrences(first: 50, startsBefore: $startsBefore, startsAfter: $startsAfter, datetimeFilter: $datetimeFilter, excludeCommunityEvents: $excludeCommunityEvents, includedProductIds: $includedProductIds, hasRecording: $hasRecording, onlyDistinctDates: true) {\n        totalCount\n        edges {\n          node {\n            id\n            datetimeRange\n            __typename\n          }\n          cursor\n        }\n        pageInfo {\n          startCursor\n          endCursor\n          hasNextPage\n          hasPreviousPage\n        }\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "88c14f32145ef04c812f37527fb7e1b0";

export default node;
